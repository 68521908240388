import React from "react";
import { Chart, Line } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import { Grid, Card, Box, Link, Button } from "@material-ui/core";
import * as CONST from "../../constants";
import BarChartIcon from '@material-ui/icons/BarChart';
import TimelineIcon from '@material-ui/icons/Timeline'; Chart.register([annotationPlugin]);
interface TimeGraphProps {
  data: any;
}

export const TimeGraph: React.FC<TimeGraphProps> = ({ data }) => {
  const label = data.label;
  const [usePercentages, setUsePercentages] = React.useState(false);
  const [showDailyCounts, setShowDailyCounts] = React.useState(false);
  const monthlyCounts = data.monthly.map((hsh: any) => hsh['count']);
  const monthlyPercentages = data.monthly.map((hsh: any) => hsh['percentage']);
  const monthlyLabels = data.monthlyLabels;
  let dataPoints;
  let currentMonthData = data.monthly[data.monthly.length - 1];
  let sortedCurrentDailyLabels = Object.keys(currentMonthData.daily).sort((a,b) => {
    return (new Date(a).valueOf() - new Date(b).valueOf());
  });

  let labels = showDailyCounts == true ? sortedCurrentDailyLabels : monthlyLabels;

  if (showDailyCounts) {
    dataPoints = sortedCurrentDailyLabels.map((dateLabel) => {
      return currentMonthData.daily[dateLabel];
    });
  } else {
    if (usePercentages) {
      dataPoints = monthlyPercentages;
    } else {
      dataPoints = monthlyCounts;
    }
  }

  const data1 = {
    labels: [...labels],
    datasets: [
      {
        label: data.label,
        data: dataPoints,
        fill: false,
        backgroundColor: CONST.COHORT_1_COLOR,
        hoverBorderColor: CONST.TARGET_GREEN,
        hoverBorderWidth: 3,
        borderColor: "#02265db8",
      },
    ],
  };

  const options = CONST.DEFAULT_TIMELINEGRAPH_OPTIONS;

  const resultsTable = () => (
    <table id="qm">
      <thead>
        <tr>
          <th>
          </th>
          <th></th>
          {monthlyLabels.map((i) => (
            <th id="right" key={i}>
              {i}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td id="key">
            <span id="key-dot" className="on-wP">
              &#183;
            </span>
          </td>
          <td id="cohort">{label}</td>

          {data.monthly.map((hsh: any, index: number) => {
            const val = usePercentages == true ? hsh['percentage'] + "%" : hsh['count'] + ' of ' + hsh['all'];
            return (
              <td id="right" key={index + "electedPcp"}>
                <span id="value" className="on-wP">
                  {val}
                </span>
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );

  return (
    <>
      <b>Showing by { showDailyCounts == true ? 'Day' : 'Month'}</b>
      <Line type="line" id="line-graph" width={9} height={5} options={options} data={data1} />
      {resultsTable()}
      <Grid container spacing={10}>
        <Grid style={{ 'display': 'Flex' }} className="d-flex" item xs>
          { Object.values(currentMonthData.daily).length > 0 && (
            <Button
              variant="text"
              onClick={(el) => {
                setUsePercentages(false);
                setShowDailyCounts(!showDailyCounts)
              }}
            >
              <TimelineIcon /> {showDailyCounts == true ? 'Monthly' : 'Daily'}
            </Button>
          )}
          <Button
            variant="text"
            onClick={(el) => {
              setShowDailyCounts(false);
              setUsePercentages(!usePercentages)
            }}
          >
            <BarChartIcon /> {usePercentages == true ? 'count' : 'percent'}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default TimeGraph;
