import React from "react";
import { Box, Grid } from "@material-ui/core";

import * as CONST from "../constants";

interface SelectedFiltersProps {
  filters: {
    pcp_group: string;
    health_plan: string;
    filter_year: number | null;
    plan_sponsor: string;
  };
  view: string;
  isViewEstablished: boolean;
}

export const SelectedFilters: React.FC<SelectedFiltersProps> = React.memo(
  ({ filters, isViewEstablished, view }) => {
    return (
      <Grid container spacing={1} justify="center" alignItems="center">
        <Grid item xs={12} md={8}>
          <Box id="qm-filters-text" style={styles.borderTop}>
            {" "}
            <div style={styles.dateTime}>
              {"Current Scores as of " + new Date().toLocaleString()}
            </div>
            {filters.health_plan && (
              <label>
                {"|"} <span style={styles.label}>HEALTH PLAN</span>
                <b> {filters.health_plan} </b> {"|"}
              </label>
            )}
            {filters.pcp_group && (
              <label>
                <span style={styles.label}>PCP GROUP</span>
                <b> {filters.pcp_group} </b> {"|"}
              </label>
            )}
            {filters.pcp_group == "" && (
              <label>
                <span style={styles.label}>PCP GROUP</span>
                <b> All PCP Groups </b> {"|"}
              </label>
            )}
            {filters.filter_year == null || filters.filter_year == (new Date()).getFullYear() ? (
              <label>
                <span style={styles.label}>PERIOD</span>
                <b> Current</b>
                {" |"}
              </label>
            ) : (
              <label>
                <span style={styles.label}>PERIOD</span>
                <b> {filters.filter_year} </b>
                {"|"}
              </label>
            )}
            <br />
            <label>
              {"| "}
              <span style={styles.label}>PLAN SPONSOR </span>
              <b>{filters.plan_sponsor ? filters.plan_sponsor : "All Plan Sponsors"}</b> {"|"}
            </label>
            {view == "qm" ? (
              <>
                <label>
                  <span style={styles.label}> PATIENTS </span>
                  <b>
                    {isViewEstablished
                      ? "Established Patients Only"
                      : "Established & Not Established"}
                  </b>{" "}
                  {" |"}
                </label>
                <br />
              </>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    );
  }
);

const styles = {
  borderTop: {
    borderTop: "3px dotted #f3f3f3",
    paddingTop: ".25rem",
    paddingBottom: ".25rem",
  },
  label: {
    color: "#8e98a8",
    fontWeight: "bold",
    letterSpacing: "0.05em",
  },
  dateTime: {
    marginTop: ".25rem",
    marginBottom: ".25rem",
  },
} as const;
export default SelectedFilters;
