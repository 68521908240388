import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  box: { padding: "0px 0px 20px", width: "200px", margin: "10px auto" },
  gridGraph: {
    margin: "0px auto",
  },
}));

interface DonutProps {
  data: any;
  name: string;
  color: string;
}

export const Donut: React.FC<DonutProps> = ({ data, name, color }) => {
  const styles = useStyles();
  return (
    <>
      {" "}
      <Grid item xs={10} md={3}>
        <Box className={styles.box} position="relative" justifyContent="center" alignItems="center">
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <h1 id="doughnut-score">{Math.round(data.percent_passing * 100)}%</h1>
          </Box>
          <Doughnut
            type="doughnut"
            data={{
              datasets: [
                {
                  data: [data.percent_passing * 100, 100 - data.percent_passing * 100],
                  backgroundColor: [color, "black"],
                },
              ],
            }}
          />{" "}
        </Box>
        <p>{name}</p>
      </Grid>
    </>
  );
};

export default Donut;
