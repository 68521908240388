import React from "react";
import { Chart, Line } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import { Box } from "@material-ui/core";

import * as CONST from "../../constants";

Chart.register([annotationPlugin]);
interface TimeGraphProps {
  data: any;
}

export const TimeGraph: React.FC<TimeGraphProps> = ({ data }) => {
  const all_patients = data.monthly.all_patients;
  const with_account = data.monthly.with_account;
  const without_account = data.monthly.without_account;
  const monthly = all_patients.map((d: any) => d.date.month + "/" + d.date.year.substr(-2));
  const data1 = {
    labels: [...monthly],
    datasets: [
      {
        label: "ONYwP",
        data: with_account.map((score: any) => score.data.percent_passing * 100),
        fill: false,
        backgroundColor: CONST.COHORT_1_COLOR,
        hoverBorderColor: CONST.TARGET_GREEN,
        hoverBorderWidth: 3,
        borderColor: "#02265db8",
      },
      {
        label: "NOT ONYwP",
        data: without_account.map((score: any) => score.data.percent_passing * 100),
        fill: false,
        backgroundColor: CONST.COHORT_2_COLOR,
        hoverBorderColor: CONST.TARGET_GREEN,
        hoverBorderWidth: 3,
        borderColor: "#62717a91",
      },
      {
        label: "ALL",
        data: all_patients.map((score: any) => score.data.percent_passing * 100),
        fill: false,
        backgroundColor: CONST.COHORT_3_COLOR,
        hoverBorderColor: CONST.TARGET_GREEN,
        hoverBorderWidth: 3,
        borderColor: "#2cb5fa73",
      },
    ],
  };

  const options = CONST.TIME_LINEGRAPH_OPTIONS;

  const resultsTable = () => (
    <table id="qm">
      <thead>
        <tr>
          <th></th>
          <th></th>
          {monthly.map((i) => (
            <th id="right" key={i}>
              {i}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td id="key">
            <span id="key-dot" className="on-wP">
              &#183;
            </span>
          </td>
          <td id="cohort">{CONST.COHORT_1_SHORT}</td>

          {with_account.map((score: any, idx: number) => {
            const val = Math.round(score.data.percent_passing * 100) + "%";
            return (
              <td id="right" key={score.date.month + "with_account"}>
                <span id="value" className="on-wP">
                  {val}
                </span>
              </td>
            );
          })}
        </tr>
        <tr>
          <td id="key">
            <span id="key-dot" className="not-on-wP">
              &#183;
            </span>
          </td>
          <td id="cohort">{CONST.COHORT_2_SHORT}</td>

          {without_account.map((score: any, idx: number) => {
            const val = Math.round(score.data.percent_passing * 100) + "%";

            return (
              <td id="right" key={score.date.month + "without_account"}>
                <span id="value" className="not-on-wP">
                  {val}
                </span>
              </td>
            );
          })}
        </tr>
        <tr>
          <td id="key">
            {" "}
            <span id="key-dot" className="all">
              &#183;
            </span>
          </td>
          <td id="cohort">{CONST.COHORT_3}</td>

          {all_patients.map((score: any, idx: number) => {
            const val = Math.round(score.data.percent_passing * 100) + "%";
            return (
              <td id="right" key={score.date.month + "all_patients"}>
                <span id="value" className="all">
                  {val}
                </span>
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );

  return (
    <>
      <Box id="graph-box">
        <label id="graph-box-label">Passing Scores Over Time</label>
        <p id="target-pill" className="target">
          {"TARGET 85%"}
        </p>{" "}
      </Box>
      <Line type="line" id="line-graph" width={9} height={5} options={options} data={data1} />
      {resultsTable()}
    </>
  );
};

export default TimeGraph;
