import React from "react";
import { Grid, Card, Toolbar, Box, IconButton, Tooltip, Button } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import BarGraph from "./BarGraph";
import TimeGraph from "./TimeGraph";
import ArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import { withStyles, makeStyles } from "@material-ui/core/styles";

interface GraphWrapperProps {
  monthly: any;
  idx: number;
  description: string;
  current: any;
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 370,
    padding: 14,
    borderRadius: 7,
    textAlign: "left",
    lineHeight: 1.4,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export const GraphWrapper: React.FC<GraphWrapperProps> = ({
  monthly,
  idx,
  current,
  description,
}) => {
  const [showTimeGraph, setShowTimeGraph] = React.useState(false);
  const componentRef = React.useRef<HTMLElement | null>(null);

  return (
    <Grid item xs={12} md={6} key={idx} id="qm-grid-item">
      <Card id="graph-card" ref={componentRef}>
        <IconButton
          id="function-icon-btn"
          aria-label="view changes over time"
          onClick={() => (!showTimeGraph ? setShowTimeGraph(true) : setShowTimeGraph(false))}
        >
          {showTimeGraph ? <ArrowLeft /> : <ArrowRight />}
        </IconButton>{" "}
        <b>
          <label id="graph-title">{current.name}</label>
        </b>
        <HtmlTooltip
          title={
            description ? (
              <>
                <b>{current.name.toUpperCase()}</b>
                <i>
                  <p>{description}</p>
                </i>
              </>
            ) : (
              <>
                {" "}
                <b>{current.name.toUpperCase()}</b>
                <p>Sorry, no description available</p>
              </>
            )
          }
        >
          <IconButton id="qm-info-btn" aria-label="`${description}`">
            <InfoIcon id="info-graph-icon" />
          </IconButton>
        </HtmlTooltip>
        <ReactToPrint
          trigger={() => (
            <IconButton>
              <PrintIcon id="print-icon" />
            </IconButton>
          )}
          content={() => componentRef.current}
        />
        {showTimeGraph && <TimeGraph data={{ monthly, current }} key={idx + "-monthly"} />}
        {!showTimeGraph && (
          <BarGraph
            key={idx + "-current"}
            data={{
              current,
            }}
          />
        )}
      </Card>
    </Grid>
  );
};

const styles = {
  description: {
    lineHeight: "1.4",
    padding: "12px",
  },
} as const;
export default GraphWrapper;
