import * as React from "react";
import BarIcon from "@material-ui/icons/BarChartRounded";
import PeopleIcon from "@material-ui/icons/SupervisorAccountRounded";
import RedeemIcon from "@material-ui/icons/Redeem";

interface DashboardTitleProps {
  title: string;
  view: string;
}

const DashboardTitle: React.FC<DashboardTitleProps> = ({ title, view }) => {
  return (
    <h1 id="title">
      {view == "qm" && <BarIcon id="big-big-icon" />}
      {view == "pem" && <PeopleIcon id="big-big-icon" />}
      {view == "rewards" && <RedeemIcon id="big-big-icon" />}

      <br />
      {title}
      <br />
    </h1>
  );
};

export default DashboardTitle;
