import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";

interface RowProps {
  data: any;
  idx: number;
  hideNames: boolean;
}

const useStyles = makeStyles(() => ({
  verticalBar: {
    color: "#001269",
    fontSize: "3em",
    marginRight: "12px",
  },
  container: {
    padding: "0 10px 0 0",
    borderBottom: "0px solid ghostwhite",
  },
  box: { padding: "0px 0px 20px", width: "180px", margin: "0px auto" },

  left: {
    textAlign: "left",
    padding: "0 10px",
  },

  right: {
    textAlign: "right",
  },
  commentsGrid: {
    maxHeight: "550px",
    paddingRight: "0px",
    overflowX: "hidden",
    overflowY: "scroll",
  },
  comments: {
    width: "60%",
    textAlign: "left",
    verticalAlign: "middle",
  },
  comment: {
    marginBottom: "-2px",
    marginTop: "0",
    verticalAlign: "top",
    color: "#000",
  },
  commentDate: {
    fontSize: "9px",
    margin: "1px",
    color: "#62717a",
  },

  tdAvatar: {
    width: "32px",
    paddingLeft: "10px",
    verticalAlign: "middle",
  },
  avatar: {
    backgroundColor: "#62717a63",
    color: "white",
    padding: "0px",
    fontSize: "12px",

    width: "32px",
    height: "32px",
  },
  npsScore: { width: "80px", verticalAlign: "middle" },
  tdPcp: {
    verticalAlign: "middle",
  },
  NaN: {
    width: "150px",
  },
  tdNps: { verticalAlign: "middle", textAlign: "right", paddingRight: "12px", paddingLeft: "10px" },
}));

export const Row: React.FC<RowProps> = ({ data, idx, hideNames }) => {
  const [show, setShow] = React.useState(false);
  const styles = useStyles();

  const expandRow = () =>
    data.survey_questions ? (
      data.survey_questions.map((i, idx) => {
        var str = Object.keys(i).map((key) => key);
        var valStr = Object.keys(i).map((key) => i[key]);
        return (
          <tr key={idx}>
            <td></td>
            <td>
              {str} <br />
              <b>{valStr}</b>
            </td>
            <td></td>
            <td></td>
          </tr>
        );
      })
    ) : (
      <></>
    );
  return (
    <>
      <tr key={idx} id="trExpand" onClick={() => (!show ? setShow(true) : setShow(false))}>
        <td className={styles.tdAvatar}>
          <Avatar className={styles.avatar}>
            {hideNames ? (
              <PersonIcon />
            ) : (
              data.patient_first_name.substring(0, 1) + data.patient_last_name.substring(0, 1)
            )}
          </Avatar>
        </td>
        <td className={styles.comments}>
          <p className={styles.comment}>{data.comment}</p>
          <small>
            {" "}
            {hideNames
              ? "Patient Comment on "
              : data.patient_first_name + " " + data.patient_last_name + " on "}
          </small>
          <small className={styles.commentDate}>{data.date}</small>
        </td>
        <td id="right" className={styles.tdPcp}>
          <small>PCP </small>
          {data.pcp_first_name + " " + data.pcp_last_name}
        </td>

        {parseInt(data.nps_score) == NaN ? (
          <td id="nps-score-comments" className={styles.NaN}>
            {data.nps_score}
          </td>
        ) : (
          <td id="nps-score-comments" className={styles.tdNps}>
            {data.nps_score}
          </td>
        )}
      </tr>
      {show && expandRow()}
    </>
  );
};

export default Row;
