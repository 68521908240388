import React from "react";
import { Grid, Card, Toolbar, Box, IconButton, Tooltip, Button } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import { Bar, Doughnut } from "react-chartjs-2";

interface GraphWrapperProps {
  i: any;
  filters: any;
  idx: number;
}

export const GraphWrapper: React.FC<GraphWrapperProps> = ({ i, filters, idx }) => {
  const componentRef = React.useRef<HTMLElement | null>(null);

  return (
    <Grid item key={i.name} xs={12} sm={12} md={6} lg={6}>
      <Card ref={componentRef} style={styles.graphCard}>
        <b>
          <label>{i.name} </label>
        </b>
        <ReactToPrint
          trigger={() => (
            <IconButton style={styles.printBtn}>
              <PrintIcon id="print-icon" />
            </IconButton>
          )}
          content={() => componentRef.current}
        />
        <br />
        <Bar
          key={i.name}
          width={8}
          height={4}
          type="bar"
          id="bar"
          options={{
            scale: {
              ticks: {
                maxTicksLimit: 5,
              },
            },
            indexAxis: "y",
            responsive: true,
            plugins: {
              legend: {
                position: "bottom",
                labels: {
                  usePointStyle: true,
                  font: {
                    size: 12,
                    weight: "light",
                  },
                },
              },
            },
          }}
          data={{
            labels: [""],
            datasets: [
              {
                stack: 1,
                label: i.name + " (Patients Rewarded)",
                data: [i.count],
                barThickness: 25,
                backgroundColor: "#001269",
                borderRadius: 20,
                hoverBorderWidth: 2,
                borderWidth: 2,
                borderColor: "white",
                fill: false,
              },
            ],
          }}
        />
        <br />
        <div style={styles.p}>
          <b>{i.count} </b> <b id="thin">Earned Rewards ({filters.filter_year}) </b>
          <br />
          <b>
            {"$" + Number.parseFloat(i.total_amount_rewarded).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
          </b>{" "}
          <b id="thin">Total Rewards Given</b>
          <p>
            <b>EARNED BY: </b>
            {i.description ? i.description : ""} <br />
            <b>REWARD AMOUNT: </b>{" "}
            {"$ " + Number.parseFloat(i.reward_amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </p>
        </div>
      </Card>
    </Grid>
  );
};

const styles = {
  printBtn: {
    padding: "5px",
  },
  name: {
    fontSize: "14px",
    marginLeft: "5px",
    textTransform: "uppercase",
  },
  p: { textAlign: "left" },
  div: {
    padding: "7rem 20rem",
    borderRadius: "10px",
    background: "#eef2f887",
  },

  graphCard: {
    boxShadow: "none",
    margin: "auto",
    padding: "20px 0",
    textAlign: "left",
  },
} as const;
export default GraphWrapper;
