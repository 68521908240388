import React from "react";
import logo from "../assets/images/wellportal.png";
import { Link } from "react-router-dom";
export const Logo: React.FC = () => (
  <Link
    id="left-align"
    to={{ pathname: "https://www.yourwellportal.com/physicians/dashboard" }}
    target="blank"
  >
    <img src={logo} alt="logo" id="logo" />
  </Link>
);
export default Logo;
