import React from "react";
import { Grid, Box, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import ChevronR from "@material-ui/icons/ChevronRight";
import ChevronL from "@material-ui/icons/ChevronLeft";
import SwapIcon from "@material-ui/icons/UnfoldMoreRounded";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import Row from "./Row";

interface CommentsProps {
  comments: {
    type: string;
    subtype: string;
    data: any;
  };
  hideNames: boolean;
}

const useStyles = makeStyles(() => ({
  verticalBar: {
    color: "#001269",
    fontSize: "3em",
    marginRight: "12px",
  },
  container: {
    padding: "0 0px 0 0",

    borderBottom: "0px solid ghostwhite",
  },
  box: { padding: "0px 0px 20px", width: "180px", margin: "0px auto" },

  left: {
    textAlign: "left",
    padding: "0 10px",
  },

  right: {
    textAlign: "right",
  },
  rightGrid: {
    marginTop: "20px",
    paddingLeft: "0",
  },
  commentsGrid: {
    maxHeight: "600px",
    paddingRight: "0px",
    border: "1px solid ghostwhite",
    overflowX: "hidden",
    overflowY: "scroll",
  },
  comments: {
    minWidth: "150px",
    textAlign: "left",
  },
  comment: {
    marginBottom: "-2px",
    color: "#000",
  },
  commentDate: {
    fontSize: "10px",
    margin: "1px",
    color: "#62717a",
  },
  avatar: {
    backgroundColor: "#f6f6f7",
    color: "#62717a",
    fontSize: "12px",
    marginLeft: "20px",
  },
  npsScore: { width: "80px" },
  NaN: {
    width: "150px",
  },
  chevron: {
    fontSize: "20px",
    marginBottom: "-5px",
  },
  sort: {
    padding: "3px",
    marginRight: " -10px",
  },
  swapIcon: {
    color: "black",
  },
  clear: {
    padding: "5px",
  },
  input: {
    fontSize: "12px",
    padding: "5px 0",
  },
}));
export const Comments: React.FC<CommentsProps> = ({ comments, hideNames }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [filterComments, setFilterComments] = React.useState([""]);
  const [isSearching, setIsSearching] = React.useState(false);
  const [sortedAsc, setSortedAsc] = React.useState(false);
  const [commentsData, setCommentsData] = React.useState([...comments.data]);

  const sortByNpsAsc = () => {
    let sortedComments = commentsData;
    if (!sortedAsc) {
      sortedComments.sort((a, b) => Number(parseInt(a.nps_score)) - Number(parseInt(b.nps_score)));
      setSortedAsc(true);
      return setCommentsData(sortedComments);
    } else {
      sortedComments.sort((a, b) => Number(parseInt(b.nps_score)) - Number(parseInt(a.nps_score)));
      setSortedAsc(false);
    }
  };

  let commentsToShow = !isSearching ? commentsData : filterComments;

  const [currentPage, setCurrentPage] = React.useState(1);
  const commentsPerPage = 10;
  const idxOfLast = currentPage * commentsPerPage;
  const idxOfFirst = idxOfLast - commentsPerPage;
  const currentComments = commentsToShow.slice(idxOfFirst, idxOfLast);
  const totalPages = Math.ceil(commentsToShow.length / commentsPerPage);

  const pageClickHandler = (payload: number) => setCurrentPage(payload);

  const pagination = () =>
    totalPages !== 0 && (
      <>
        <button
          className="page-link"
          disabled={currentPage === 1}
          onClick={() => pageClickHandler(currentPage - 1)}
        >
          <ChevronL className={styles.chevron} />
        </button>
        {" Page " + currentPage + " of " + totalPages}

        <button
          className="page-link"
          disabled={currentPage === totalPages}
          onClick={() => pageClickHandler(currentPage + 1)}
        >
          <ChevronR className={styles.chevron} />
        </button>
      </>
    );

  totalPages == 0 && <></>;

  const showComments = () => {
    return (
      <table id="comments">
        <tbody>
          {currentComments.map((c: any, idx: number) => {
            let date = new Date(c.created_at.substring(0, 10)).toLocaleString("default", {
              month: "long",
              year: "numeric",
              day: "2-digit",
              hour: "numeric",
              minute: "numeric",
            });

            const data = { ...c, date };

            return <Row data={data} idx={idx} key={idx} hideNames={hideNames} />;
          })}
        </tbody>
      </table>
    );
  };

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length >= 0) {
      setIsSearching(true);

      let searchString = e.target.value.toLowerCase(); //set searchString variable to
      let searchResults = commentsData.filter((obj: any) =>
        Object.values(obj).some((val: any) =>
          val ? val.toString().toLowerCase().includes(searchString) : false
        )
      );
      setFilterComments(searchResults);
      setCurrentPage(1);
    } else {
      setIsSearching(false);
    }
  };
  const onClearInput = () => {
    setIsSearching(false);
    if (inputRef.current !== null) {
      inputRef.current.value = "";
    }
  };

  const styles = useStyles();

  return (
    <Grid item xs={12} sm={11} md={6} className={styles.rightGrid}>
      <Box id="input-box">
        <label>Patient Feedback</label>{" "}
        {commentsData && (
          <>
            <p>See what patients have to say. Search patient comments by name or PCP.</p>
            <TextField
              label={<SearchIcon id="search" />}
              className={styles.input}
              size="small"
              fullWidth
              ref={inputRef}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSearchInputChange(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear search"
                      onClick={() => {
                        onClearInput();
                      }}
                    >
                      {isSearching ? <ClearRoundedIcon id="clear" /> : ""}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
        {totalPages == 0 && <p>No Comments yet... Check back later</p>}
      </Box>

      <Grid container id="container">
        <Grid item xl className={styles.comments}>
          <p>
            {" "}
            {" Showing " + currentComments.length + " of " + commentsToShow.length + " Comments "}
            {pagination()}
          </p>
        </Grid>
        {totalPages !== 0 && (
          <Grid item xs className={styles.right}>
            <p>
              {" "}
              {"NPS"}
              <button
                className="page-link"
                aria-label="sort"
                disabled={isSearching}
                onClick={() => sortByNpsAsc()}
              >
                <SwapIcon className={styles.chevron} />
              </button>
            </p>
          </Grid>
        )}{" "}
      </Grid>
      <Box className={styles.commentsGrid}>{showComments()}</Box>
    </Grid>
  );
};
export default Comments;
