import React from "react";
import IconButton from "@material-ui/core/Button";
import LeftIcon from "@material-ui/icons/ChevronLeft";
import { useHistory } from "react-router-dom";
interface BackButtonProps {
  token: any;
}
export const BackButton: React.FC<BackButtonProps> = (token) => {
  const history = useHistory();

  const params = "/?token=" + token.token.toString();

  return (
    <IconButton id="back-btn" onClick={() => history.push(params)}>
      <LeftIcon id="back-icon" />
    </IconButton>
  );
};
export default BackButton;
