import * as React from "react";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import ErrorIcon from "@material-ui/icons/Error";
import Layout from "./Layout";
interface VerificationErrorProps {
  msg: string;
}

const VerificationError: React.FC<VerificationErrorProps> = ({ msg }) => {
  return (
    <Layout>
      <Card className="alert">
        <h1>
          <ErrorIcon style={{ fontSize: "3.5rem" }} />
        </h1>
        <h4 className="alert-msg">{msg && msg}</h4>
        <Divider className="divider" />
        <p className="p"></p>
      </Card>
    </Layout>
  );
};

export default VerificationError;
