import * as React from "react";
import { Grid, Card, Box, Link, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import * as CONST from "../constants";
import SelectPlanSponsor from "./SelectPlanSponsor";
import SelectField from "./SelectField";
interface FiltersProps {
  dispatch: (param: any) => void;
  filterClickHandler: (params: any) => void;
  state: any;
  view: string;
  message: string;
}

const Filters: React.FC<FiltersProps> = ({
  dispatch,
  state,
  view,
  message,
  filterClickHandler,
}) => {
  const [hidePcpSelector, setHidePcpSelector] = React.useState(false);
  // console.log(state);
  // const periods =
  //   view == "rewards" ? CONST.REWARDS_PERIODS : view == "qm" ? CONST.PERIODS : CONST.PE_PERIODS;
  const healthPlans = view == "rewards" ? CONST.REWARDS_HEALTH_PLANS : CONST.HEALTH_PLANS;

  var healthPlanData = state.allHealthPlans.filter((i) => i['title'] == state.filters.health_plan)[0];
  var startingYear = healthPlanData ? healthPlanData['starting_year'] : 2020;
  var periods = CONST.PERIODS.filter((year) => year >= parseInt(startingYear));

  const onChange = (params: any) => {
    dispatch({ type: "set_is_fetching", payload: true });
    const queryParams = {
      token: state.user.token,
      health_plan: params.health_plan,
      pcp_group: params.pcp_group,
      plan_sponsor: params.plan_sponsor,
      filter_year: params.filter_year,
    };

    filterClickHandler({ ...queryParams });
  };

  const healthPlanVal =
    view == "qm" || view == "rewards"
      ? state.filters.health_plan
      : view == "pem"
      ? state.peFilters.health_plan
      : "";
  const pcpVal =
    view == "qm" || view == "rewards"
      ? state.filters.pcp_group
      : view == "pem"
      ? state.peFilters.pcp_group
      : "";

  const yearVal =
    view == "pem"
      ? state.peFilters.filter_year
      : view == "qm"
      ? state.filters.filter_year
      : view == "rewards" && state.filters.filter_year == "Cumulative"
      ? "Cumulative"
      : state.filters.filter_year;

  const filters = view == "qm" || view == "rewards" ? state.filters : state.peFilters;

  const getPcpOptions = (filters: { health_plan: string }) => {
    if (filters.health_plan == "Prominence Health Plan") {
      return CONST.PCP_GROUPS_PHP.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ));
    } else if (filters.health_plan == "Wynn Resorts Health Plan") {
      return CONST.PCP_GROUPS_WYNN.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ));
    } else {
      return CONST.PCP_GROUPS.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ));
    }
  };

  return (
    <>
      {" "}
      <div style={styles.message}>{message}</div>
      <Grid item xs={12} className="filters-wrapper">
        {state.user.type !== "HealthPlan" && state.user.type !== "PlanSponsor" && (
          <select
            className="default"
            value={healthPlanVal}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              if (
                state.filters.health_plan !== e.target.value ||
                state.peFilters.health_plan !== e.target.value
              ) {
                dispatch({
                  type: "set_plan_sponsors_options",
                  payload: state.allPlanSponsors.filter((i) => i.health_plan == e.target.value),
                });
                onChange({
                  ...filters,
                  plan_sponsor: "",
                  health_plan: e.target.value,
                });
              }
            }}
          >
            {healthPlans.map((option) => (
              <option value={option} key={option}>
                {option}
              </option>
            ))}
          </select>
        )}

        {state.user.type !== "PcpGroup" && !hidePcpSelector && (
          <select
            value={pcpVal}
            className="default"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              onChange({ ...filters, pcp_group: e.target.value });
            }}
          >
            {getPcpOptions(filters)}
          </select>
        )}
        <select
          className="default"
          value={yearVal}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            onChange({
              ...filters,
              filter_year: e.target.value,
            });
          }}
        >
          {periods.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </Grid>
      <Grid container style={styles.planSponsor} justify="center" alignItems="center">
        <Grid item xs={12}>
          {" "}
          <div style={styles.label}>SEARCH & SELECT A PLAN SPONSOR</div>{" "}
        </Grid>
        <Grid item md={3} xs={6} style={styles.selectPlanSponsor}>
          <SearchIcon style={styles.search} />
          <SelectPlanSponsor
            state={state}
            view={view}
            dispatch={dispatch}
            onChange={(param) => {
              onChange({ ...filters, plan_sponsor: !param ? "" : param.value });
              if (view == "qm") {
                param.value ? setHidePcpSelector(true) : setHidePcpSelector(false);
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

const styles = {
  selectPlanSponsor: { paddingBottom: "1rem" },
  message: {
    color: "#8e98a8",
    marginBottom: "1rem",
    marginTop: "-1rem",
    letterSpacing: "0.125em",
  },
  search: {
    fontSize: "1.5rem",
    float: "left",
    color: "#8e98a8",
    margin: "12px 5px 0px 0px",
  },
  label: {
    color: "#8e98a8",
    marginTop: ".25rem",
    letterSpacing: "0.07em",
    fontWeight: "normal",
    marginBottom: ".25rem",
  },
  planSponsor: {
    paddingBottom: ".5rem",
    paddingTop: ".5rem",
    fontSize: "10px",
  },
} as const;

export default Filters;
