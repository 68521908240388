import * as React from "react";
import Filters from "../../components/Filters";
import Loading from "../../components/Loading";
import GraphWrapper from "./GraphWrapper";
import SelectedFilters from "../../components/SelectedFilters";
import DashboardTitle from "../../components/DashboardTitle";
import BackButton from "../../components/BackButton";
import * as CONST from "../../constants";
import * as API from "../../apis";
import Donut from "./Donut";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Card, Link, IconButton, FormHelperText } from "@material-ui/core";
import { Bar } from "react-chartjs-2";
import CompositeIcon from "@material-ui/icons/BarChartRounded";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

interface QualityMeasuresProps {
  state: any;
  dispatch: (param: any) => void;
}

const StyledSwitch = withStyles({
  switchBase: {
    color: "#041353",
    "&$checked": {
      color: "#2cb5fa",
    },
    "&$checked + $track": {
      backgroundColor: "#2cb5fa",
    },
  },
  checked: {},
  track: {},
})(Switch);

const QualityMeasures: React.FC<QualityMeasuresProps> = ({ state, dispatch }) => {
  const [loading, setLoading] = React.useState(true);
  const componentRef = React.useRef<HTMLElement | null>(null);
  const [togglePcpComposites, setTogglePcpComposites] = React.useState(false);
  const [store, setStore] = React.useState({
    composites: null,
    monthly: null,
    established_composites: {} as any,
    established_monthly: {
      data: [] as any,
      descriptions: [] as any,
      filter_year: null,
      health_plan: null,
      filter_month: null,
      pcp_group: null,
    },
  } as any);

  const getQm = async ({ ...params }) => {
    const qmComposites = await API.fetchQmCompositeScores({ ...params });
    const monthly = await API.fetchQm({ ...params });
    const data = {
      established_monthly: {
        data: [
          ...monthly.data.map((i: any) => {
            return {
              data: { ...i.data.established },
              filter_year: i.filter_year,
              filter_month: i.filter_month,
            };
          }),
        ],
        descriptions: monthly.descriptions,
        filter_year: monthly.filter_year,
        filter_month: monthly.filter_month,
        health_plan: monthly.health_plan,
        pcp_group: monthly.pcp_group,
      },
      established_composites: {
        data: qmComposites.established,
        health_plan: params.health_plan,
      },
      composites: { data: qmComposites, health_plan: params.health_plan },
      monthly: monthly,
    };
    setStore({ ...data });
    return data;
  };

  React.useEffect(() => {
    (async () => {
      const queryParams = {
        token: state.user.token,
        health_plan: state.user.type == "HealthPlan" ? state.user.id : CONST.HEALTH_PLANS[0],
        pcp_group: state.user.type == "PcpGroup" ? state.user.id : CONST.PCP_GROUPS[0],
        plan_sponsor: state.user.type == "PlanSponsor" ? state.user.id : null,
        filter_year: state.filters.filter_year == "Cumulative" ? (new Date()).getFullYear() : state.filters.filter_year,
      };
      dispatch({ type: "set_current_filters", payload: queryParams });
      const data = await getQm({ ...queryParams });
      dispatch({
        type: "set_qm_pcp_composite_scores",
        payload: !state.isViewEstablished ? data.composites : data.established_composites,
      });
      dispatch({
        type: "set_qm",
        payload: !state.isViewEstablished ? data.monthly : data.established_monthly,
      });
      setTimeout(() => {
        setLoading(false);
      }, 900);
    })();
  }, []);

  const filterChangeHandler = async (params: any) => {
    !loading && setLoading(true);
    if (
      params.health_plan !== state.filters.health_plan ||
      params.filter_year !== state.filters.filter_year ||
      params.pcp_group !== state.filters.pcp_group ||
      params.plan_sponsor !== state.filters.plan_sponsor
    ) {
      dispatch({ type: "set_current_filters", payload: params });

      const data = await getQm({ ...params });
      dispatch({
        type: "set_qm_pcp_composite_scores",
        payload: !state.isViewEstablished ? data.composites : data.established_composites,
      });
      dispatch({
        type: "set_qm",
        payload: !state.isViewEstablished ? data.monthly : data.established_monthly,
      });
    }
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  const renderPcpComposites = (props: any) => {
    const data = {
      labels: props.map((i: { pcp_group: string }) => i.pcp_group),
      datasets: [
        {
          label: "Composite % Scores by PCP Group",
          data: props.map((i: { scores: { percent_passing: number } }) =>
            Math.round(i.scores.percent_passing * 100)
          ),
          barThickness: 20,
          borderRadius: 20,
          hoverBorderWidth: 1,
          borderWidth: 1,
          borderColor: "white",
          fill: false,
          backgroundColor: CONST.COHORT_1_COLOR,
          hoverBorderColor: CONST.TARGET_GREEN,
        },
      ],
    };
    return (
      <Bar type="bar" data={data} id="pcp-bar" options={CONST.PCP_COMPOSITES_BARGRAPH_OPTIONS} />
    );
  };

  const toggleSwitch = async () => {
    if (!state.isViewEstablished) {
      dispatch({ type: "set_is_view_established", payload: true });
      dispatch({
        type: "set_qm_pcp_composite_scores",
        payload: store.established_composites,
      });
      dispatch({
        type: "set_qm",
        payload: store.established_monthly,
      });
    } else {
      dispatch({ type: "set_is_view_established", payload: false });
      dispatch({
        type: "set_qm_pcp_composite_scores",
        payload: store.composites,
      });
      dispatch({ type: "set_qm", payload: store.monthly });
    }
  };
  return (
    <>
      {loading && <Loading msg={state.error ? state.error : CONST.MSG_FETCHING_DATA} />}
      {!loading && (
        <Card ref={componentRef} id="card">
          <BackButton token={state.user.token} />
          <DashboardTitle title="Quality Measures" view="qm" />
          <label style={styles.filterMessage}>Filter Quality Measures By</label>

          <Filters
            dispatch={dispatch}
            filterClickHandler={(params) => filterChangeHandler(params)}
            state={state}
            view={"qm"}
            message={""}
          />
          <FormGroup>
            <FormControlLabel
              style={styles.switch}
              control={<StyledSwitch checked={state.isViewEstablished} onChange={toggleSwitch} />}
              labelPlacement="start"
              label={
                <>
                  <b style={styles.switchText}>{CONST.ESTABLISHED_SWITCH}</b>
                </>
              }
            />
          </FormGroup>
          <div style={styles.establishedCaption}>{CONST.ESTABLISHED_SWITCH_CAPTION}</div>

          <Grid container justify="space-around" alignItems="flex-start">
            <Grid item xs={12}>
              <SelectedFilters
                filters={state.filters}
                view={"qm"}
                isViewEstablished={state.isViewEstablished}
              />
              <ReactToPrint
                trigger={() => (
                  <IconButton id="print-btn">
                    Print Report
                    <PrintIcon id="print-icon" />
                  </IconButton>
                )}
                content={() => componentRef.current}
              />
            </Grid>

            <Grid container spacing={0} justify="space-around" alignItems="center">
              <Grid item xs={12}>
                <div id="big">
                  <CompositeIcon id="composite-icon" />
                  Composite Quality Measures Score
                </div>
                <p id="composite-copy">{CONST.QM_COMPOSITE_COPY}</p>
                <Grid container justify="center" alignItems="center">
                  <Grid item xs={12} sm={10}>
                    <Link
                      onClick={() => {
                        !togglePcpComposites
                          ? setTogglePcpComposites(true)
                          : setTogglePcpComposites(false);
                      }}
                    >
                      {!togglePcpComposites
                        ? "See composite scores by PCP Group"
                        : "Hide composite scores by PCP Group"}
                    </Link>
                    {togglePcpComposites && renderPcpComposites(state.pcpComposites)}
                  </Grid>
                </Grid>
              </Grid>
              <Donut
                color={CONST.COHORT_1_COLOR}
                data={state.qm.dataSet[0].results.with_account.data}
                name={CONST.COHORT_1}
              />
              <Donut
                color={CONST.COHORT_1_COLOR}
                data={state.qm.dataSet[0].results.without_account.data}
                name={CONST.COHORT_2}
              />
              <Donut
                color={CONST.COHORT_1_COLOR}
                data={state.qm.dataSet[0].results.all_patients.data}
                name={CONST.COHORT_3}
              />{" "}
            </Grid>

            {state.qm.dataSet.map((current: any, idx: number) => {
              return (
                <GraphWrapper
                  key={idx}
                  idx={idx}
                  current={current}
                  monthly={current.monthly}
                  description={current.description}
                />
              );
            })}
          </Grid>
        </Card>
      )}
    </>
  );
};

const styles = {
  filterMessage: {
    color: "#8e98a8",
  },
  establishedCaption: {
    textAlign: "center",
    color: "#8e98a8",
    letterSpacing: "0.03em",
    margin: "-.5rem auto 1rem",
    paddingBottom: "1rem",
  },
  switchText: {
    textTransform: "capitalize",
  },
  switch: {
    display: "inline-block",
    marginTop: "-1rem",
  },
} as const;

export default QualityMeasures;
