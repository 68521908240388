import queryString from "query-string";
import * as dotenv from "dotenv";
import axios from "axios";
import * as CONST from "../constants";

export async function verifyToken(param: string) {
  const res = await axios.post(
    queryString.stringifyUrl({
      url: `${process.env.REACT_APP_VERIFY_TOKEN_API}`,
      query: { token: param },
    })
  );
  return res;
}
export async function fetchSettings(params: any) {
  try {
    return await axios.get(
      queryString.stringifyUrl({
        url: `${process.env.REACT_APP_SETTINGS}`,
        query: { token: params.token, health_plan: params.health_plan },
      })
    );
  } catch (error) {
    return error;
  }
}

export async function fetchPemData(query: any) {
  async function req(params: any) {
    const request = queryString.stringifyUrl({
      url: `${process.env.REACT_APP_PATIENT_EXPERIENCE_API}`,
      query: params,
    });
    const res = await fetch(request);
    if (!res.ok) {
      const json = await res.json();
      throw json;
    }
    const data = await res.json();
    return data;
  }

  const response = await Promise.all([
    ...CONST.REPORTS.map(async (i) => {
      const params = {
        survey_subtype: i.survey_subtype,
        survey_type: i.survey_type,
        token: query.token,
        plan_sponsor: query.plan_sponsor,
        filter_year: query.filter_year,
        health_plan: query.health_plan ? query.health_plan : null,
        pcp_group: query.pcp_group == "All PCP Groups" ? null : query.pcp_group,
        report_type: i.report_type,
      };
      return {
        ...params,
        data: await req({
          ...params,
        }),
      };
    }),
  ]);
  return response;
}

export async function fetchPeMeasuresComments(query: any) {
  async function req(params: any) {
    const request = queryString.stringifyUrl({
      url: `${process.env.REACT_APP_PATIENT_EXPERIENCE_COMMENTS}`,
      query: params,
    });
    const res = await fetch(request);
    const data = await res.json();
    return data;
  }

  const res = await Promise.all([
    ...CONST.SURVEY_TYPES.map(async (i) => {
      return {
        data: await req({
          survey_subtype: i.survey_subtype,
          survey_type: i.survey_type,
          filter_year: query.filter_year,
          plan_sponsor: query.plan_sponsor,
          token: query.token,
          health_plan: query.health_plan ? query.health_plan : null,
          pcp_group: query.pcp_group === "All PCP Groups" ? null : query.pcp_group,
        }),
        subtype: i.survey_subtype,
        filter_year: query.filter_year,
        health_plan: query.health_plan ? query.health_plan : null,
        pcp_group: query.pcp_group ? query.pcp_group : null,
        type: i.survey_type,
        report_type: "comments",
      };
    }),
  ]);

  return res;
}

export async function fetchPatientEngagementData(query: any) {
  const request = queryString.stringifyUrl({
    url: `${process.env.REACT_APP_PATIENT_ENGAGEMENT_API}`,
    query: {
      token: query.token,
      health_plan: query.health_plan,
      filter_year: query.filter_year,
      plan_sponsor: query.plan_sponsor,
    },
  });
  const res = await fetch(request);
  return res;
}

export async function fetchPatientEngagementMonthlyData(query: any) {
  const request = queryString.stringifyUrl({
    url: `${process.env.REACT_APP_PATIENT_ENGAGEMENT_MONTHLY_API}`,
    query: {
      token: query.token,
      health_plan: query.health_plan,
      filter_year: query.filter_year,
      plan_sponsor: query.plan_sponsor,
    },
  });
  const res = await fetch(request);
  return res;
}

export async function fetchPemCompositeScores(query: any) {
  const request = queryString.stringifyUrl({
    url: `${process.env.REACT_APP_PEM_COMPOSITES}`,
    query: {
      token: query.token,
      health_plan: query.health_plan,
      filter_year: query.filter_year,
      plan_sponsor: query.plan_sponsor,
    },
  });
  const res = await fetch(request);
  if (!res.ok) {
    const json = await res.json();
    throw json;
  }
  const data = await res.json();

  return data;
}

export async function fetchQmCompositeScores(query: any) {
  const request = queryString.stringifyUrl({
    url: `${process.env.REACT_APP_QM_COMPOSITES}`,
    query: {
      token: query.token,
      health_plan: query.health_plan,
      filter_year: query.filter_year,
      plan_sponsor: query.plan_sponsor,
    },
  });
  const res = await fetch(request);
  if (!res.ok) {
    const json = await res.json();
    throw json;
  }
  const data = await res.json();

  return data;
}

export async function fetchQm(query: any) {
  const request = queryString.stringifyUrl({
    url: `${process.env.REACT_APP_QM_CHANGE_OVER_TIME_API}`,
    query: {
      token: query.token,
      health_plan: query.health_plan,
      plan_sponsor: query.plan_sponsor,
      pcp_group: query.pcp_group === "All PCP Groups" ? null : query.pcp_group,
      filter_year: query.filter_year,
    },
  });
  const res = await fetch(request);

  if (!res.ok) {
    const json = await res.json();
    throw json;
  }
  const data = await res.json();
  return data;
}

export async function fetchRewards(query: any) {
  const request = queryString.stringifyUrl({
    url: `${process.env.REACT_APP_REWARDS_API}`,
    query: {
      token: query.token,
      plan_sponsor: query.plan_sponsor,
      health_plan: query.health_plan,
      pcp_group: query.pcp_group === "All PCP Groups" ? null : query.pcp_group,
      filter_year: query.filter_year,
    },
  });
  const res = await fetch(request);

  if (!res.ok) {
    const json = await res.json();
    throw json;
  }
  const data = await res.json();
  return data;
}
