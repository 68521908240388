import React from "react";

export const Icon: React.FC = () => (
  <svg
    style={{ paddingTop: "50px" }}
    id="big-big-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="12%"
    height="12%"
    fill="none"
    viewBox="0 0 95 71"
  >
    <rect
      width="16.811"
      height="55.926"
      x="0.664"
      y="7.769"
      fill="#b0c2dd52"
      rx="8.406"
      transform="rotate(-25 .664 7.77)"
    ></rect>
    <rect
      width="16.811"
      height="68.926"
      x="28.665"
      y="7.769"
      fill="#b0c2dd52"
      rx="8.406"
      transform="rotate(-25 28.665 7.77)"
    ></rect>
    <rect
      width="16.811"
      height="16.81"
      x="70.769"
      y="2.864"
      fill="#b0c2dd52"
      rx="8.405"
      transform="rotate(25 78.77 .664)"
    ></rect>
  </svg>
);

export default Icon;
