import * as React from "react";
import Loading from "../../components/Loading";
import { Grid, Card, Box, Link, IconButton } from "@material-ui/core";
import { Bar } from "react-chartjs-2";
import CompositeIcon from "@material-ui/icons/BarChartRounded";
import * as API from "../../apis";
import * as CONST from "../../constants";

import { Doughnut } from "react-chartjs-2";
import { makeStyles } from "@material-ui/core/styles";
import TimeGraph from "./TimeGraph";

import { ErrorFallback } from "../ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";
import ArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import SelectedFilters from "../../components/SelectedFilters";
import DashboardTitle from "../../components/DashboardTitle";
import Filters from "../../components/Filters";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import BackButton from "../../components/BackButton";

const useStyles = makeStyles(() => ({
  marginTop: {
    paddingTop: '50px',
    display: 'block'
  },
  patientEngagementGrid: {
    float: 'right',
    margin: '30px',
    width: '27%'
  },
  bolded: {
    fontSize: '20px'
  },
  description: {
    fontSize: '20px',
    padding: '30px',
    margin: 'auto',
    textAlign: 'left'
  },
  descriptionCenter: {
    width: '600px',
    fontSize: '20px',
    margin: '30px auto',
    textAlign: 'left'
  }
}));

interface PatientEngagementProps {
  state: any;
  dispatch: (param: any) => void;
}

const PatientEngagement: React.FC<PatientEngagementProps> = ({ state, dispatch }) => {
  const styles = useStyles();
  const [showTimeGraph, setShowTimeGraph] = React.useState(false);
  const [togglePcpComposites, setTogglePcpComposites] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const reportRef = React.useRef<HTMLElement | null>(null);
  const [pcpSelected, setPcpSelected] = React.useState(false);
  const componentRef = React.useRef<HTMLElement | null>(null);
  let error = state.error;
  let monthly = state.patient_engagement ? state.patient_engagement.data : null;
  let arrLength = state.patient_engagement ? state.patient_engagement.data.length - 1 : 0;
  let currentPatientEngagementReport =  state.patient_engagement && state.patient_engagement.data ? state.patient_engagement.data[arrLength] : null;

  React.useEffect(() => {
    (async () => {
      const queryParams = {
        token: state.user.token,
        health_plan: state.user.type == "HealthPlan" ? state.user.id : CONST.HEALTH_PLANS[0],
        pcp_group: state.user.type == "PcpGroup" ? state.user.id : CONST.PCP_GROUPS[0],
        plan_sponsor: state.user.type == "PlanSponsor" ? state.user.id : null,
        filter_year: state.peFilters.filter_year ? state.peFilters.filter_year : null,
      }
      const res = await API.fetchPatientEngagementMonthlyData({ ...queryParams });

      if (res.ok) {
        let data = await res.json();
        dispatch({
          type: "set_patient_engagement_data",
          payload: data,
        });
        setLoading(false);
      } else {
        dispatch({
          type: "set_error_fetching",
          payload: 'No report found!',
        })
      }
    })()}, []
  );

  const healthPlans = CONST.HEALTH_PLANS;
  let healthPlanVal = state.patient_engagement ? state.patient_engagement.health_plan : healthPlans[0],
    currentYear = new Date().getFullYear(),
    periods = [currentYear,currentYear - 1, currentYear - 2],
    yearVal = state.patient_engagement ? state.patient_engagement.filter_year : periods[0];
  return(<>
    {loading && <Loading msg={state.error ? state.error : CONST.MSG_FETCHING_DATA} />}
    {!loading && (
      <>
        <Card id="qm-dashboard" className={styles.marginTop} ref={reportRef}>
          <BackButton token={state.user.token} />
          <DashboardTitle title="Patient Engagement" view="engagement" />
          <select
            className="default"
            value={healthPlanVal}
            onChange={async (e: React.ChangeEvent<HTMLSelectElement>) => {
              const queryParams = {
                token: state.user.token,
                health_plan: e.target.value,
                filter_year: state.peFilters.filter_year ? state.peFilters.filter_year : null,
              }
              const res = await API.fetchPatientEngagementMonthlyData({ ...queryParams });
              if (res.ok) {
                let data = await res.json();
                dispatch({
                  type: "set_patient_engagement_data",
                  payload: data,
                });
                setLoading(false);
              } else {
                dispatch({
                  type: "set_error_fetching",
                  payload: 'No report found!',
                })
              }
            }}
          >
            {healthPlans.map((option) => (
              <option value={option} key={option}>
                {option}
              </option>
            ))}
          </select>
          <select
            className="default"
            value={yearVal}
            onChange={async (e: React.ChangeEvent<HTMLSelectElement>) => {
              const queryParams = {
                token: state.user.token,
                health_plan: healthPlanVal,
                filter_year: e.target.value,
              }
              const res = await API.fetchPatientEngagementMonthlyData({ ...queryParams });
              if (res.ok) {
                let data = await res.json();
                dispatch({
                  type: "set_patient_engagement_data",
                  payload: data,
                });
                setLoading(false);
              } else {
                dispatch({
                  type: "set_error_fetching",
                  payload: 'No report found!',
                })
              }
            }}
          >
            {periods.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <div className={styles.descriptionCenter}>
            These reports display relevant graphs and statistics on patient engagement with our PCPs and programs.
          </div>
          {state.error != '' && <>
            <div className={styles.descriptionCenter}> { state.error }</div>
          </>}
          {state.error == '' && (<div>
            <Grid item xs={6} sm={6} className={styles.patientEngagementGrid}>
              <h1>
                Elected PCP
                <IconButton
                  aria-label="view changes over time"
                  onClick={() => (!showTimeGraph ? setShowTimeGraph(true) : setShowTimeGraph(false))}
                >
                  {showTimeGraph ? <ArrowLeft /> : <ArrowRight />}
                </IconButton>{" "}
              </h1>
              {
                showTimeGraph && (<>
                  <TimeGraph
                    data={{
                      label: "Elected Pcp",
                      monthly: monthly.map((d: any) => {
                        return {
                          percentage: Math.round(d.data.elected_pcp.percentage * 100),
                          count: d.data.elected_pcp.elected_pcp,
                          all: d.data.elected_pcp.all,
                          daily: d.data.elected_pcp.daily
                        };
                      }),
                      monthlyLabels: monthly.map((d: any) => d.filter_month + "/" + d.filter_year)
                    }}
                    key={"test-monthly"}
                  />
                </>)
              }
              {
                !showTimeGraph && (<>
                  <Box
                    position="relative"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <h3>
                        { currentPatientEngagementReport.data.elected_pcp.percentage_string }
                      </h3>
                    </Box>
                    <Doughnut
                      type="doughnut"
                      options={{
                        plugins: {
                          legend: {
                            position: "bottom",
                            labels: {
                              usePointStyle: true,
                              font: {
                                size: 12,
                                weight: "normal",
                              },
                            },
                          },
                        },
                      }}
                      data={{
                        labels: ['Has elected PCP', 'No elected PCP'],
                        datasets: [
                          {
                            data: [currentPatientEngagementReport.data.elected_pcp.percentage, 1 - currentPatientEngagementReport.data.elected_pcp.percentage],
                            backgroundColor: [
                              "cornflowerblue",
                              "ghostwhite",
                              "slategrey",
                              "skyblue",
                              "#26b6fa",
                              CONST.TARGET_GREEN,
                            ],
                          },
                        ],
                      }}
                    />{" "}
                  </Box>
                  <div>
                    { currentPatientEngagementReport.data.elected_pcp ? currentPatientEngagementReport.data.elected_pcp.elected_pcp : 0 } Patients with Elected PCPs
                    <br />
                    { currentPatientEngagementReport.data.elected_pcp ? currentPatientEngagementReport.data.elected_pcp.all : 0 } Overall Eligible Patients (In Area)
                  </div>
                  <div className={styles.description}>
                    <b className={styles.bolded}>‘Elected PCP’</b> shows the percentage of eligible patients who have elected a wellPORTAL PCP.
                  </div>
                </>)
              }

            </Grid>
            <Grid item xs={6} md={6} className={styles.patientEngagementGrid}>
              <h1>
                Established
                <IconButton
                  aria-label="view changes over time"
                  onClick={() => (!showTimeGraph ? setShowTimeGraph(true) : setShowTimeGraph(false))}
                >
                  {showTimeGraph ? <ArrowLeft /> : <ArrowRight />}
                </IconButton>{" "}
              </h1>
              {
                showTimeGraph && (<>
                  <TimeGraph
                    data={{
                      label: "Established",
                      monthly: monthly.map((d: any) => {
                        return {
                          percentage: Math.round(d.data.established.percentage * 100),
                          count: d.data.established.established,
                          all: d.data.established.all,
                          daily: d.data.established.daily
                        };
                      }),
                      monthlyLabels: monthly.map((d: any) => d.filter_month + "/" + d.filter_year)
                    }}
                    key={"test-monthly"}
                  />
                </>)
              }
              {
                !showTimeGraph && (<>
                  <Box
                    position="relative"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <h3>
                        {currentPatientEngagementReport.data.established.percentage_string}
                      </h3>
                    </Box>
                    <Doughnut
                      type="doughnut"
                      options={{
                        plugins: {
                          legend: {
                            position: "bottom",
                            labels: {
                              usePointStyle: true,
                              font: {
                                size: 12,
                                weight: "normal",
                              },
                            },
                          },
                        },
                      }}
                      data={{
                        labels: ['Established', 'Not Established'],
                        datasets: [
                          {
                            data: [currentPatientEngagementReport.data.established.percentage, 1 - currentPatientEngagementReport.data.established.percentage],
                            backgroundColor: [
                              "cornflowerblue",
                              "ghostwhite",
                              "slategrey",
                              "skyblue",
                              "#26b6fa",
                              CONST.TARGET_GREEN,
                            ],
                          },
                        ],
                      }}
                    />{" "}
                  </Box>
                  <div>
                    { currentPatientEngagementReport.data.established ? currentPatientEngagementReport.data.established.established : 0 } Established Patients
                    <br />
                    { currentPatientEngagementReport.data.established ? currentPatientEngagementReport.data.established.all : 0 } Overall Eligible Patients (In Area, have elected a PCP)
                  </div>
                  <div className={styles.description}>
                    <b className={styles.bolded}>‘Established with PCP’</b> shows the percentage of patients who have elected a wellPORTAL PCP and completed at least one visit with them in the last 15 months.
                  </div>
                </>)};
            </Grid>
            <Grid item xs={6} md={6} className={styles.patientEngagementGrid}>
              <h1>
                Registered
                <IconButton
                  aria-label="view changes over time"
                  onClick={() => (!showTimeGraph ? setShowTimeGraph(true) : setShowTimeGraph(false))}
                >
                  {showTimeGraph ? <ArrowLeft /> : <ArrowRight />}
                </IconButton>{" "}
              </h1>
              {
                showTimeGraph && (<>
                  <TimeGraph
                    data={{
                      label: "Registered",
                      monthly: monthly.map((d: any) => {
                        return {
                          percentage: Math.round(d.data.registered.percentage * 100),
                          count: d.data.registered.registered,
                          all: d.data.registered.all,
                          daily: d.data.registered.daily
                        };
                      }),
                      monthlyLabels: monthly.map((d: any) => d.filter_month + "/" + d.filter_year)
                    }}
                    key={"test-monthly"}
                  />
                </>)
              }
              { !showTimeGraph && (
                <>
                  <Box
                    position="relative"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <h3>
                        {currentPatientEngagementReport.data.registered.percentage_string}
                      </h3>
                    </Box>
                    <Doughnut
                      type="doughnut"
                      options={{
                        plugins: {
                          legend: {
                            position: "bottom",
                            labels: {
                              usePointStyle: true,
                              font: {
                                size: 12,
                                weight: "normal",
                              },
                            },
                          },
                        },
                      }}
                      data={{
                        labels: ['Registered', 'Not Registered'],
                        datasets: [
                          {
                            data: [currentPatientEngagementReport.data.registered.percentage, 1 - currentPatientEngagementReport.data.registered.percentage],
                            backgroundColor: [
                              "cornflowerblue",
                              "ghostwhite",
                              "slategrey",
                              "skyblue",
                              "#26b6fa",
                              CONST.TARGET_GREEN,
                            ],
                          },
                        ],
                      }}
                    />{" "}
                  </Box>
                  <div>
                    { currentPatientEngagementReport.data.registered ? currentPatientEngagementReport.data.registered.registered : 0 } Registered Patients
                    <br />
                    { currentPatientEngagementReport.data.registered ? currentPatientEngagementReport.data.registered.all : 0 } Overall Eligible Patients
                  </div>
                  <div className={styles.description}>
                    <b className={styles.bolded}>‘Registered’</b> shows the percentage of eligible patients who have created an account on Your wellPORTAL.
                  </div>
                </>
              )}
            </Grid>
          </div>)}
        </Card>
      </>
    )}
  </>);
};
export default PatientEngagement;
