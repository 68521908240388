import * as React from "react";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import { Grid } from "@material-ui/core";
import BackButton from "../components/BackButton";

export function ErrorFallback({ error }) {
  const params = window.location.search;
  const urlParams = new URLSearchParams(params);
  const token = urlParams.get("token");
  return (
    <>
      <div style={{ marginTop: "100px" }}>
        <BackButton token={token} />
        <Grid container justify="center" alignItems="center">
          <Grid item xs={10} sm={8} md={6}>
            <h1>
              <ErrorRoundedIcon id="big-icon" />
              No Data available.
            </h1>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
