import * as React from "react";

interface CostAndUtilizationProps {
  state: any;
  dispatch: (param: any) => void;
}

const CostAndUtilization: React.FC<CostAndUtilizationProps> = ({ state, dispatch }) => {
  return <></>;
};

export default CostAndUtilization;
