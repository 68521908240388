import { stat } from "fs";
import React, { Fragment } from "react";
import Select, { ActionMeta, StylesConfig } from "react-select";

interface SelectPlanSponsorProps {
  state: any;
  onChange: (param: any) => void;
  view: string;
  dispatch: (param: any) => void;
}
interface SelectProps {
  Option: unknown;
}

const SelectPlanSponsor: React.FC<SelectPlanSponsorProps> = React.memo(
  ({ state, onChange, view, dispatch }) => {
    let health_plan = view == "pem" ? state.peFilters.health_plan : state.filters.health_plan;
    const [planSponsors, setPlanSponsors] = React.useState(
      state.user.type == "HealthPlan"
        ? [
            ...state.allPlanSponsors.map((j) => {
              return {
                value: j.name,
                label: j.name,
              };
            }),
          ]
        : state.user.type == "Admin"
        ? [
            ...state.allPlanSponsors
              .filter((j) => j.health_plan == health_plan)
              .map((i) => {
                return {
                  value: i.name,
                  label: i.name,
                };
              }),
          ]
        : state.planSponsors.map((j) => {
            return {
              value: j.name,
              label: j.name,
            };
          })
    );
    React.useEffect(() => {
      console.log(planSponsors, "before mount");

      setPlanSponsors(
        [...state.allPlanSponsors.filter((i: any) => i.health_plan == health_plan)].map((j) => {
          return {
            value: j.name,
            label: j.name,
          };
        })
      );
    }, []);

    const [value, setValue] = React.useState({
      label: view == "pem" ? state.peFilters.plan_sponsor : state.filters.plan_sponsor,
      value: view == "pem" ? state.peFilters.plan_sponsor : state.filters.plan_sponsor,
    });

    const customStyles: StylesConfig = {
      menu: (styles, state) => ({
        ...styles,
        border: "1px solid #70717517",
        textTransform: "capitalize",
        letterSpacing: "0.06em",
        fontSize: "10px",
        // width: "70%",
        fontWeight: "normal",
      }),
      control: (styles, state) => ({
        ...styles,
        backgroundColor: state.isFocused ? "#f6f6f6" : "white",
        borderRadius: "20px",
        // width: "70%",
        outline: "none",
        textTransform: "capitalize",
        lineHeight: "1",
        fontSize: "10px",
        height: "30px",
        fontWeight: "unset",
        minHeight: "34px",
        padding: "0px",
        margin: "3px auto 5px 5px",
        border: "1px solid #6d727d26",
        //   color: "rgb(0, 0, 0)",
      }),
    };
    return (
      <Fragment>
        <Select
          styles={customStyles}
          className="select-plan-sponsor"
          classNamePrefix="select"
          defaultValue={value}
          isDisabled={state.user.type == "PlanSponsor" ? true : false}
          isClearable={value.value ? true : false}
          onChange={(option: any | { value: ""; label: "" }, ActionMeta: any) => {
            if (ActionMeta === "clear") {
              setValue({ label: "", value: "" });
              onChange({ value: "" });
              view == "pem"
                ? dispatch({
                    type: "set_current_pe_filters",
                    payload: { ...state.peFilters, plan_sponsor: "" },
                  })
                : dispatch({
                    type: "set_current_filters",
                    payload: { ...state.filters, plan_sponsor: "" },
                  });
            } else {
              setValue({ ...option });
              onChange(option);
            }
          }}
          isSearchable={true}
          placeholder={"Search or select a plan sponsor"}
          options={[...planSponsors]}
        />
      </Fragment>
    );
  }
);

export default SelectPlanSponsor;
