import React from "react";
import { Grid, Card, Box, Button, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Bar } from "react-chartjs-2";
import * as CONST from "../../constants";
import Comments from "./Comments";

interface NetPromoterProps {
  data: any;
  comments: any;
  hideNames: boolean;
}

const useStyles = makeStyles(() => ({
  container: {
    padding: "0px",
  },
  tdPercent: {
    textAling: "left",
  },
  scaleTh: {
    width: "150px",
  },

  box: { padding: "0px 0px 20px", width: "180px", margin: "0px auto" },

  score: { margin: "0px -30px 0 0px" },
  statement: {
    maxWidth: "none",
  },
  left: {
    textAlign: "left",
    padding: "0 10px",
  },

  row: {
    height: "20px",
  },
  npsCard: {
    margin: "10px 0",
    textAlign: "center",
    boxShadow: "none",
  },
  right: {
    textAlign: "right",
  },

  h6: {
    fontSize: ".85em",
    margin: "0",
  },
}));
export const NetPromoter: React.FC<NetPromoterProps> = ({ data, comments, hideNames }) => {
  const styles = useStyles();

  const renderTable = ({ tableData }) => {
    return (
      <>
        <table id="nps">
          <thead>
            <tr>
              <th>{"Survey Question"}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{tableData.surveyQuestion}</td>
            </tr>
          </tbody>
        </table>

        <table id="nps">
          <thead>
            <tr>
              <th>{"Detractors (Rated 0-6)"}</th>
              <th>{"Passives (Rated 7-8)"}</th>
              <th>{"Promoters (Rated 9-10)"}</th>
              <th>{"Total Patients"}</th>
            </tr>
          </thead>
          <tbody>
            <tr className={styles.row}>
              <td>{tableData.detractors}</td>
              <td>{tableData.passives}</td>
              <td>{tableData.promoters}</td>
              <td>{tableData.promoters + tableData.passives + tableData.detractors}</td>
            </tr>
            <tr className={styles.row}>
              <td className={styles.tdPercent}>{tableData.detractorsPercent + "%"}</td>
              <td className={styles.tdPercent}>{tableData.passivesPercent + "%"}</td>
              <td className={styles.tdPercent}>{tableData.promotersPercent + "%"}</td>
            </tr>
          </tbody>
        </table>

        <table id="nps">
          <thead>
            <tr>
              <th className={styles.scaleTh}>{"Rating Breakdown"}</th>

              {tableData.npsData.map((n: any) => (
                <th key={n.option}>{n.option}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td id="label">Patient Count</td>
              {tableData.npsData.map((n: any) => (
                <td key={n.option + "-count"}>{n.count}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  const renderMapNps = ({ data, comments }) =>
    data.map(
      (c: {
        survey_type: string;
        pcp_group: string;
        health_plan: string;
        survey_subtype: string;
        data: any;
      }) => {
        const survey_type = c.survey_type;
        const npsData = c.data;
        const pcp = c.pcp_group == null ? "All PCP Groups" : c.pcp_group;
        const subtype = c.survey_subtype == "telehealth" ? "Telehealth" : "Clinic";

        const count = npsData.map((i: any) => {
          let count = 0;
          return (count = count + i.count);
        });

        const detractors = count.slice(0, 7).reduce((a, b) => a + b, 0);
        const passives = count.slice(7, 9).reduce((a, b) => a + b, 0);
        const promoters = count.slice(9, 11).reduce((a, b) => a + b, 0);
        const total = detractors + passives + promoters;
        const commentsData = comments.filter((t) => t.type == survey_type);

        const tableData = {
          detractors: detractors,
          passives: passives,
          promoters: promoters,
          total: total,
          detractorsPercent: Math.round((detractors / total) * 100),
          passivesPercent: Math.round((passives / total) * 100),
          promotersPercent:
            100 - (Math.round((detractors / total) * 100) + Math.round((passives / total) * 100)),
          commentsData: comments.filter((t) => t.type == survey_type),
          surveyQuestion: npsData[0].statement,
          npsData: npsData,
        };

        const graphData = {
          labels: ["Percent Scores"],
          datasets: [
            {
              stack: 1,
              label: "Detractors (0-6)",
              data: [tableData.detractorsPercent],
              barThickness: 25,
              borderRadius: 20,
              hoverBorderWidth: 2,
              borderWidth: 2,
              borderColor: "white",
              fill: false,
              backgroundColor: "black",
              hoverBorderColor: CONST.TARGET_GREEN,
            },
            {
              stack: 2,
              label: "Passives (7-8)",

              data: [tableData.passivesPercent],
              barThickness: 25,
              borderRadius: 20,
              hoverBorderWidth: 2,
              borderWidth: 2,
              borderColor: "white",
              fill: false,
              backgroundColor: CONST.COHORT_2_COLOR,
              hoverBorderColor: CONST.TARGET_GREEN,
            },
            {
              label: "Promoters (9-10)",
              stack: 3,
              data: [tableData.promotersPercent],
              barThickness: 25,
              borderRadius: 20,
              hoverBorderWidth: 2,
              borderWidth: 2,
              borderColor: "white",
              fill: false,
              backgroundColor: CONST.COHORT_1_COLOR,
              hoverBorderColor: CONST.TARGET_GREEN,
            },
          ],
        };

        return (
          <Card id="graph-card" key={survey_type}>
            <Box id="graph-box-l">
              {" "}
              <b>
                <label id="margin-btm">{survey_type.replaceAll("_", " ")} </label>
              </b>
              <small>{" " + subtype + " NPS (Net Promoter Score) "}</small>
              <br />
              <small>{pcp}</small>
            </Box>
            {commentsData[0] && (
              <Grid container justify="center" alignItems="flex-start">
                <Grid item xs={12} md={6}>
                  <Bar
                    type="bar"
                    id="pe-bar-nps"
                    width={10}
                    height={8}
                    options={CONST.VERTICAL_BARGRAPH_OPTIONS}
                    data={graphData}
                  />
                  {renderTable({ tableData })}
                </Grid>{" "}
                <Comments comments={commentsData[0]} hideNames={hideNames} />
              </Grid>
            )}
            {!commentsData[0] && (
              <Grid container spacing={3} justify="center" alignItems="flex-start">
                <Grid item xs={12} sm={11}>
                  <Bar
                    type="bar"
                    id="pe-bar-nps"
                    width={10}
                    height={8}
                    options={CONST.VERTICAL_BARGRAPH_OPTIONS}
                    data={graphData}
                  />
                  {renderTable({ tableData })}
                </Grid>{" "}
              </Grid>
            )}
          </Card>
        );
      }
    );

  return (
    <Card className={styles.npsCard}>
      <Grid container justify="space-around" alignItems="center">
        <Grid item xs={12}>
          {" "}
          {renderMapNps({ data, comments })}
        </Grid>
      </Grid>
    </Card>
  );
};

export default NetPromoter;
