import React from "react";
import Logo from "./Logo";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Link } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  appBar: {
    height: "60px",
    borderBottom: "1px solid #0012690d",
    boxShadow: "1px 1px 1px rgb(69 90 100 / 1%)",
    padding: "0px",
    backgroundColor: "#ffffff",
  },
}));

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
  const classes = useStyles();

  return (
    <>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Logo />
        </Toolbar>
      </AppBar>
    </>
  );
};
export default Header;
