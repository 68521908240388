import * as React from "react";
import Loading from "../../components/Loading";
import Surveys from "./Surveys";
import { Grid, Card, Box, Link, IconButton } from "@material-ui/core";
import { Bar } from "react-chartjs-2";
import CompositeIcon from "@material-ui/icons/BarChartRounded";
import * as API from "../../apis";
import * as CONST from "../../constants";

import { Doughnut } from "react-chartjs-2";
import { makeStyles } from "@material-ui/core/styles";
import NetPromoter from "./NetPromoter";

import { ErrorFallback } from "../ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";

import SelectedFilters from "../../components/SelectedFilters";
import DashboardTitle from "../../components/DashboardTitle";
import Filters from "../../components/Filters";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import BackButton from "../../components/BackButton";

const useStyles = makeStyles(() => ({
  box: { padding: "0px 0px 20px", width: "200px", margin: "10px auto" },
  gridGraph: {
    margin: "0px auto",
  },
}));

interface PatienExperienceProps {
  state: any;
  dispatch: (param: any) => void;
}

const PatientExperience: React.FC<PatienExperienceProps> = ({ state, dispatch }) => {
  const styles = useStyles();
  const [togglePcpComposites, setTogglePcpComposites] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [telehealth, setTelehealth] = React.useState(false);
  const [pcpSelected, setPcpSelected] = React.useState(false);
  const componentRef = React.useRef<HTMLElement | null>(null);

  const getComposites = async ({ ...params }) => {
    const composites = await API.fetchPemCompositeScores({
      ...params,
    });

    dispatch({
      type: "set_pe_pcp_composite_scores",
      payload: { data: composites, health_plan: params.health_plan },
    });
  };

  const getData = async ({ ...params }) => {
    const data = await API.fetchPemData({ ...params });
    const comments = await API.fetchPeMeasuresComments({
      ...params,
    });
    return {
      data: data,
      comments: comments,
    };
  };

  const filterChangeHandler = async (params: any) => {
    !loading && setLoading(true);
    const queryParams = { token: state.user.token, ...params };
    if (state.user.type == "Admin") {
      dispatch({
        type: "set_plan_sponsors_options",
        payload: state.allPlanSponsors.filter(
          (i: { health_plan: any }) => i.health_plan == params.health_plan
        ),
      });
    }
    if (
      params.health_plan !== state.peFilters.health_plan ||
      params.filter_year !== state.peFilters.filter_year ||
      params.pcp_group !== state.peFilters.pcp_group ||
      params.plan_sponsor !== state.peFilters.plan_sponsor
    ) {
      dispatch({ type: "set_current_pe_filters", payload: params });
      getComposites({ ...queryParams });
    } else {
      setPcpSelected(true);
    }
    const data = await getData({ ...queryParams });
    dispatch({
      type: "set_pe_data",
      payload: data,
    });

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  React.useEffect(() => {
    (async () => {
      const queryParams = {
        token: state.user.token,
        health_plan: state.user.type == "HealthPlan" ? state.user.id : CONST.HEALTH_PLANS[0],
        pcp_group: state.user.type == "PcpGroup" ? state.user.id : CONST.PCP_GROUPS[0],
        plan_sponsor: state.user.type == "PlanSponsor" ? state.user.id : null,
        filter_year: state.peFilters.filter_year ? state.peFilters.filter_year : null,
      };
      dispatch({ type: "set_current_pe_filters", payload: queryParams });
      const data = await getData({ ...queryParams });
      dispatch({
        type: "set_pe_data",
        payload: data,
      });

      getComposites({ ...queryParams });

      API.fetchSettings({
        token: state.user.token,
      })
        .then((response: any) => {
          let planSponsors = response.data.plan_sponsors;
          let healthPlans = response.data.health_plans;
          dispatch({ type: "set_all_health_plans", payload: healthPlans });
          dispatch({ type: "set_all_plan_sponsors", payload: planSponsors });
          if (state.user.type == "PlanSponsor") {
            dispatch({
              type: "set_plan_sponsor_health_plan",
              payload: response.data.health_plan,
            });
          }
          if (state.user.type == "HealthPlan") {
            dispatch({
              type: "set_plan_sponsors_options",
              payload: planSponsors,
            });
          }
        })
        .catch((err) => console.log(err));

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })();
  }, []);

  const viewChangeHandler = () => {
    if (!telehealth) {
      setTelehealth(true);
    } else {
      setTelehealth(false);
    }
  };

  const renderPcpComposites = (props: any) => {
    const data = {
      labels: props.map((i: { pcp_group: string }) => i.pcp_group),
      datasets: [
        {
          label: "Composite % Scores by PCP Group",
          data: props.map((i: { composite_score: number }) => i.composite_score),
          barThickness: 20,
          borderRadius: 20,
          hoverBorderWidth: 1,
          borderWidth: 1,
          borderColor: "white",
          fill: false,
          backgroundColor: CONST.COHORT_1_COLOR,
          hoverBorderColor: CONST.TARGET_GREEN,
        },
      ],
    };
    return (
      <Bar
        width={10}
        height={8}
        type="bar"
        data={data}
        id="pcp-bar"
        options={CONST.PCP_COMPOSITES_BARGRAPH_OPTIONS}
      />
    );
  };

  const Composite = () => {
    let compositeToShow =
      pcpSelected || state.peFilters.pcp_group !== "All PCP Groups"
        ? state.peMeasures.compositeScoresByPcp.filter(
            (i: { pcp_group: string }) => i.pcp_group == state.peFilters.pcp_group
          )[0]
        : state.peMeasures.composite;
    return (
      <Card id="card">
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item xs={12}>
            <div id="big">
              <CompositeIcon id="composite-icon" />
              Composite Patient Experience Measures
            </div>

            <Grid container spacing={1} justify="center" alignItems="center">
              <Grid item xs={5}>
                <p>{CONST.PEM_COMPOSITE_COPY}</p>
              </Grid>
            </Grid>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} sm={10}>
                <Link
                  onClick={() => {
                    !togglePcpComposites
                      ? setTogglePcpComposites(true)
                      : setTogglePcpComposites(false);
                  }}
                >
                  {!togglePcpComposites
                    ? "See composite scores by PCP Group"
                    : "Hide composite scores by PCP Group"}
                </Link>

                {togglePcpComposites && renderPcpComposites(state.peMeasures.compositeScoresByPcp)}
              </Grid>
            </Grid>
            <Box
              className={styles.box}
              position="relative"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <h1 id="doughnut-score">{compositeToShow.composite_score + "%"}</h1>
              </Box>
              <Doughnut
                type="doughnut"
                data={{
                  datasets: [
                    {
                      data: [
                        compositeToShow.composite_score,
                        100 - compositeToShow.composite_score,
                      ],
                      backgroundColor: [CONST.COHORT_1_COLOR, "black"],
                    },
                  ],
                }}
              />{" "}
            </Box>
            <p>{compositeToShow.pcp_group}</p>
          </Grid>
        </Grid>
      </Card>
    );
  };

  return (
    <>
      {loading && <Loading msg={CONST.MSG_FETCHING_DATA} />}
      {!loading && (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Card id="qm-dashboard" ref={componentRef}>
            <BackButton token={state.user.token} />
            <DashboardTitle title="Patient Experience Measures" view="pem" />

            <Filters
              dispatch={dispatch}
              filterClickHandler={(params) => filterChangeHandler(params)}
              state={state}
              view={"pem"}
              message={"FILTER PATIENT EXPERIENCE MEASURES BY"}
            />

            <SelectedFilters view={"pem"} filters={state.peFilters} isViewEstablished={false} />
            <ReactToPrint
              trigger={() => (
                <IconButton id="print-btn">
                  Print Report
                  <PrintIcon id="print-icon" />
                </IconButton>
              )}
              content={() => componentRef.current}
            />
            <Composite />

            {!telehealth && (
              <>
                <Surveys
                  data={state.peMeasures.clinic}
                  isTelehealth={false}
                  viewClickHandler={() => viewChangeHandler()}
                />

                <NetPromoter
                  hideNames={state.user.type == "PlanSponsor" ? true : false}
                  data={state.peMeasures.clinic.nps}
                  comments={state.peMeasures.clinic.comments}
                />
              </>
            )}
            {telehealth && (
              <>
                <Surveys
                  data={state.peMeasures.telehealth}
                  isTelehealth={true}
                  viewClickHandler={() => viewChangeHandler()}
                />

                <NetPromoter
                  hideNames={state.user.type == "PlanSponsor" ? true : false}
                  data={state.peMeasures.telehealth.nps}
                  comments={state.peMeasures.telehealth.comments}
                />
              </>
            )}
          </Card>
        </ErrorBoundary>
      )}
    </>
  );
};

export default PatientExperience;
