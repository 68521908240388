import React from "react";
import { Grid, Box, Card, Link, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PeopleIcon from "@material-ui/icons/SupervisorAccountRounded";
import * as CONST from "../../constants";
import { Chart, Bar, Doughnut } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import { ErrorFallback } from "../ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";

Chart.register([annotationPlugin]);

interface survey_item {
  statement: string;
  no: number;
  yes: number;
  questionid: number;
}

interface response_rate_item {
  messagecampaigntitle: string;
  noresponses: number;
  responses: number;
}

interface nps_item {
  count: number;
  option: string | number;
  statement: string;
}

interface comment_item {
  comment: string;
  created_at: string;
  nps_score: string;
  patient_first_name: string;
  patient_last_name: string;
  pcp_first_name: string;
  pcp_last_name: string;
  survey_questions: any;
}

interface survey_result {
  health_plan: string;
  pcp_group: string;
  report_type: string;
  survey_subtype: string;
  survey_type: string;
  token: string;
  data: survey_item[];
}

interface survey_response_rate {
  health_plan: string;
  pcp_group: string;
  report_type: string;
  survey_subtype: string;
  survey_type: string;
  token: string;
  data: response_rate_item[];
}

interface nps {
  health_plan: string;
  pcp_group: string;
  report_type: string;
  survey_subtype: string;
  survey_type: string;
  token: string;
  data: nps_item[];
}

interface comment {
  health_plan: string;
  pcp_group: string;
  report_type: string;
  survey_subtype: string;
  survey_type: string;
  data: comment_item[];
}

interface SurveysProps {
  data: {
    survey_results: survey_result[];
    survey_response_rates: survey_response_rate[];
    nps: nps[];
    comments: comment[];
  };
  isTelehealth: boolean;
  viewClickHandler: () => void;
}

const useStyles = makeStyles(() => ({
  text: { margin: "0px auto 0 0px" },
  box: { width: "190px", margin: "0px auto" },
  gridGraph: {
    margin: "0px auto",
  },
  container: { paddingTop: "0px" },
  paragraph: {
    textAlign: "left",
  },
  score: { margin: "0px -30px 0 0px" },
  statement: {
    maxWidth: "none",
  },
  table: {
    padding: "0 10px",
    border: "none",
  },

  gridContainer: {
    padding: "0 20px 0 30px",
  },
}));

export const Surveys: React.FC<SurveysProps> = React.memo(
  ({ data, viewClickHandler, isTelehealth }) => {
    React.useEffect(() => {}, [data]);

    const results = data.survey_results;
    const responses = data.survey_response_rates;

    const styles = useStyles();

    const renderTable = (data: any[]) => (
      <table id="pe" className={styles.table}>
        <thead>
          <tr>
            <th>
              <label>{"Survey Questions"}</label>
            </th>
            <th>{"Yes"}</th>
            <th>{"No"}</th>
            <th id="center">{"%"}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((i: any, idx: number) => {
            if (i) {
              return (
                <tr key={idx}>
                  <td className={styles.statement}>
                    <p> {idx + 1 + ". " + i.statement}</p>
                  </td>
                  <td>{i.yes}</td>
                  <td>{i.no}</td>
                  <td>{Math.round((i.yes / (i.yes + i.no)) * 100) + "%"}</td>
                </tr>
              );
            } else {
              <tr key={idx}>
                <td className={styles.statement}>No data</td>
              </tr>;
            }
          })}
        </tbody>
      </table>
    );
    const renderResponseRate = (response: response_rate_item) => {
      if (response) {
        return (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Box className={styles.text}>
              <p>
                <b id="thin">How well are patients responding to this survey?</b>
                <br />
                {"Total Sent "}
                {response.noresponses + response.responses}
                <br />
                {"Response Count " + response.responses}
                <br />
                {"No Response " + response.noresponses}{" "}
              </p>
            </Box>
            <Box
              className={styles.box}
              position="relative"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <h1 id="pe-doughnut-score">
                  {Math.ceil(
                    (response.responses / (response.responses + response.noresponses)) * 100
                  )}
                  %
                </h1>
              </Box>
              <Doughnut
                type="doughnut"
                data={{
                  datasets: [
                    {
                      data: [
                        Math.round(
                          (response.responses / (response.responses + response.noresponses)) * 100
                        ),
                        Math.round(
                          (response.noresponses / (response.responses + response.noresponses)) * 100
                        ),
                      ],
                      backgroundColor: ["#001269", "black"],
                    },
                  ],
                }}
              />{" "}
            </Box>
          </ErrorBoundary>
        );
      } else {
        return <></>;
      }
    };

    const mapResults = ({ results, responses }) =>
      results.map(
        (i: {
          data: survey_item[];
          report_type: string;
          survey_type: string;
          survey_subtype: string;
          pcp_group: string;
          health_plan: string;
        }) => {
          const response = responses.filter(
            (r: { survey_type: string }) => r.survey_type == i.survey_type
          );
          const responseData = response[0].data[0];
          return (
            <>
              {" "}
              <Card id="graph-card" key={i.survey_type}>
                <Grid container spacing={5} justify="center" alignItems="center">
                  <Grid item xs={12} md={3}>
                    <b>
                      {" "}
                      <label>{i.survey_type.replaceAll("_", " ")}</label>
                    </b>
                    <small>
                      {" "}
                      {(i.survey_subtype == "clinic" ? " Clinic" : " Telehealth") +
                        " Survey Results"}
                    </small>

                    <br />
                    <small>{i.pcp_group == null ? "All PCP Groups " : i.pcp_group}</small>
                    {responseData && renderResponseRate(responseData)}
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Bar
                      type="bar"
                      id="pe-bar"
                      options={CONST.HORIZONTAL_BARGRAPH_OPTIONS}
                      data={{
                        labels: [...i.data.map((i: any, idx: number) => "SQ" + (idx + 1))],
                        datasets: [
                          {
                            label:
                              "% said yes on " +
                              i.survey_type.replaceAll("_", " ") +
                              "-" +
                              (i.survey_subtype == "clinic" ? "Clinic" : "Telehealth") +
                              " survey questions",
                            data: [
                              ...i.data.map((i: any) => Math.round((i.yes / (i.yes + i.no)) * 100)),
                            ],
                            barThickness: 25,
                            borderRadius: 20,
                            hoverBorderWidth: 2,
                            borderWidth: 2,
                            borderColor: "white",
                            fill: false,
                            backgroundColor: CONST.COHORT_1_COLOR,
                            hoverBorderColor: CONST.TARGET_GREEN,
                          },
                        ],
                      }}
                    />
                  </Grid>{" "}
                  <Grid item xs={12} sm={10} md={4}>
                    {renderTable(i.data)}
                  </Grid>
                </Grid>
              </Card>
            </>
          );
        }
      );

    return (
      <Card id="card">
        <Grid container spacing={1} justify="center" alignItems="center">
          <Grid item xs={8}>
            <div id="big">Patient Experience Surveys</div>

            <Box id="p-box">
              <p>
                wellPORTAL distributes two types of patient experience/satisfaction surveys.
                <br />
                <b>Customer Service</b> - measures patients' level of satisfaction with their
                customer service experience.
                <br />
                <b>Quality of Care</b> - measures patients' level of satisfaction with their PCPs
                quality of care.{" "}
              </p>
            </Box>
          </Grid>

          <Grid className={styles.container} container justify="space-around" alignItems="center">
            <Grid item xs={12}>
              <Box id="qm-filters-text">
                <Link onClick={() => viewClickHandler()}>
                  {!isTelehealth && "View Telehealth Results"}
                  {isTelehealth && "View Clinic Results"}
                </Link>
              </Box>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                {mapResults({ results, responses })}
              </ErrorBoundary>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    );
  }
);

export default Surveys;
