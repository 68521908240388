import { count } from "console";
import * as CONST from "../../constants";

type State = {
  user: { type: string; id: string; token: string };
  planSponsorHealthPlan: string;
  isFetching: boolean;
  filters: {
    pcp_group: string;
    health_plan: string;
    plan_sponsor: string;
    filter_year: string;
  };
  peFilters: any;
  pcpComposites: any;
  qm: any;
  rewards: any;
  yearlyRewards: any;
  planSponsors: any;
  allPlanSponsors: any;
  allHealthPlans: any;
  peMeasures: any;
  error: string;
  isViewEstablished: boolean;
};

type Actions =
  | { type: "set_user_session"; payload: { data: any; token: string } }
  | { type: "set_is_fetching"; payload: boolean }
  | { type: "set_error_fetching"; payload: any }
  | { type: "set_error_verifying"; payload: any }
  | { type: "set_current_filters"; payload: any }
  | { type: "set_current_pe_filters"; payload: any }
  | { type: "set_timeout"; payload: number }
  | { type: "set_qm"; payload: any }
  | { type: "set_qm_pcp_composite_scores"; payload: any }
  | { type: "set_pe_data"; payload: any }
  | { type: "set_patient_engagement_data"; payload: any }
  | { type: "set_pe_pcp_composite_scores"; payload: any }
  | { type: "set_pe_composite_score_for_pcp"; payload: any }
  | { type: "set_pe_composite_for_pcp"; payload: string }
  | { type: "set_is_view_established"; payload: boolean }
  | { type: "set_rewards_data"; payload: any }
  | { type: "set_rewards_year"; payload: string }
  | { type: "set_plan_sponsors_options"; payload: any }
  | { type: "set_all_plan_sponsors"; payload: any }
  | { type: "set_plan_sponsor_health_plan"; payload: string }
  | { type: "set_all_health_plans", payload: any }

function mapOrder(a: any[], order: any[], key: string) {
  const map = order.reduce((r, v, i) => ((r[v] = i), r), {});
  return a.sort((a, b) => map[a[key]] - map[b[key]]);
}

const Reducer = (state: State, action: Actions) => {
  switch (action.type) {
    case "set_plan_sponsor_health_plan":
      return {
        ...state,
        planSponsorHealthPlan: action.payload,
      };
    case "set_all_health_plans":
      return {
        ...state,
        allHealthPlans: action.payload,
      };
    case "set_all_plan_sponsors":
      return {
        ...state,
        allPlanSponsors: action.payload,
        planSponsors: action.payload.filter((i) => i.health_plan == state.filters.health_plan),
      };

    case "set_plan_sponsors_options":
      return {
        ...state,
        planSponsors: action.payload,
      };
    case "set_rewards_year":
      return {
        ...state,
        rewards: state.yearlyRewards.filter((yr) => yr.year == action.payload)[0],
      };
    case "set_rewards_data":
      if (action.payload[0]) {
        let cumulative = {
          year: "Cumulative",
          total_amount_rewarded: [] as any,
          overall_count: [] as any,
          data: action.payload[0].data.map((i) => {
            return {
              name: i.name,
              count: [],
              description: i.description ? i.description : "",
              total_amount_rewarded: [],
              reward_amount: i.reward_amount ? i.reward_amount : "",
            };
          }),
        };
        action.payload.map((year) => {
          (cumulative.total_amount_rewarded = [
            ...cumulative.total_amount_rewarded,
            year.total_amount_rewarded,
          ]),
            (cumulative.overall_count = [...cumulative.overall_count, year.overall_count]);

          year.data.forEach((reward) => {
            cumulative.data = [
              ...cumulative.data.map((d) =>
                d.name == reward.name ? { ...d, count: [...d.count, reward.count] } : d
              ),
            ];
          });
        });

        let cumulativeData = {
          ...cumulative,
          total_amount_rewarded: cumulative.total_amount_rewarded.reduce((a, b) => a + b),
          overall_count: cumulative.overall_count.reduce((a, b) => a + b),
          data: cumulative.data.map((i) => {
            let countTotal = i.count.reduce((a, b) => a + b);
            return {
              ...i,
              count: countTotal,
              total_amount_rewarded: i.reward_amount ? i.reward_amount * countTotal : "",
            };
          }),
        };

        const yearlyData = [
          ...action.payload.map((yr) => {
            let data = yr.data.map((reward) => {
              return {
                ...reward,
                total_amount_rewarded: reward.reward_amount * reward.count,
              };
            });
            return {
              ...yr,
              data:
                state.filters.health_plan == CONST.REWARDS_HEALTH_PLANS[0]
                  ? mapOrder(data, CONST.PHP_REWARDS_ORDER, `name`)
                  : data,
              total_amount_rewarded: yr.total_amount_rewarded ? yr.total_amount_rewarded : "",
            };
          }),
        ];
        state['filters']['filter_year'] = 'Cumulative';
        return {
          ...state,
          yearlyRewards: [
            ...yearlyData,
            {
              ...cumulativeData,
              data:
                state.filters.health_plan == CONST.REWARDS_HEALTH_PLANS[0]
                  ? mapOrder(cumulativeData.data, CONST.PHP_REWARDS_ORDER, `name`)
                  : cumulativeData.data,
            },
          ],

          rewards: {
            ...cumulativeData,
            data:
              state.filters.health_plan == CONST.REWARDS_HEALTH_PLANS[0]
                ? mapOrder(cumulativeData.data, CONST.PHP_REWARDS_ORDER, `name`)
                : cumulativeData.data,
          },
        };
      } else {
        return {
          ...state,
          yearlyRewards: [],
          rewards: { year: null, data: [] },
        };
      }

    case "set_is_view_established":
      return {
        ...state,
        isViewEstablished: action.payload,
      };
    case "set_user_session":
      if (action.payload.data.message == "Success") {
        return {
          ...state,
          user: {
            type:
              action.payload.data.recipient_type == null
                ? "Admin"
                : action.payload.data.recipient_type,
            id:
              action.payload.data.recipient_id == null
                ? "wellPORTAL Admin"
                : action.payload.data.recipient_id,
            token: action.payload.token,
          },
        };
      } else {
        return {
          ...state,
        };
      }

    case "set_error_verifying":
      return {
        ...state,
        error: action.payload,
      };

    case "set_error_fetching":
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case "set_is_fetching":
      return {
        ...state,

        isFetching: action.payload,
      };

    case "set_timeout":
      setTimeout(() => {}, action.payload);
      return {
        ...state,
        isFetching: false,
      };

    case "set_current_filters":
      return {
        ...state,
        filters: {
          plan_sponsor:
            state.user.type === "PlanSponsor" ? state.user.id : action.payload.plan_sponsor,
          health_plan:
            state.user.type === "HealthPlan" ? state.user.id : action.payload.health_plan,
          pcp_group: state.user.type === "PcpGroup" ? state.user.id : action.payload.pcp_group,
          filter_year: action.payload.filter_year
            ? action.payload.filter_year
            : state.filters.filter_year,
        },
      };
    case "set_patient_engagement_data":
      return {
        ...state,
        error: '',
        patient_engagement: {
          health_plan: action.payload.health_plan,
          filter_year: action.payload.filter_year,
          data: action.payload.data
        }
      };
    case "set_pe_data":
      return {
        ...state,

        peMeasures: {
          ...state.peMeasures,

          clinic: {
            survey_results: action.payload.data.filter(
              (el: { report_type: string; survey_subtype: string }) =>
                "survey_results" === el.report_type && "clinic" === el.survey_subtype
            ),
            survey_response_rates: action.payload.data.filter(
              (el: { report_type: string; survey_subtype: string }) =>
                "survey_response_rate" === el.report_type && "clinic" === el.survey_subtype
            ),
            nps: action.payload.data.filter(
              (el: { report_type: string; survey_subtype: string }) =>
                "nps" === el.report_type && "clinic" === el.survey_subtype
            ),
            comments: action.payload.comments.filter(
              (i: { subtype: string }) => i.subtype === "clinic"
            ),
          },
          telehealth: {
            survey_results: action.payload.data.filter(
              (el: { report_type: string; survey_subtype: string }) =>
                "survey_results" === el.report_type && "telehealth" === el.survey_subtype
            ),
            survey_response_rates: action.payload.data.filter(
              (el: { report_type: string; survey_subtype: string }) =>
                "survey_response_rate" === el.report_type && "telehealth" === el.survey_subtype
            ),
            nps: action.payload.data.filter(
              (el: { report_type: string; survey_subtype: string }) =>
                "nps" === el.report_type && "telehealth" === el.survey_subtype
            ),
            comments: action.payload.comments.filter(
              (i: { subtype: string; type: string }) =>
                i.subtype === "telehealth" && i.type !== "customer_service"
            ),
          },
        },
      };

    case "set_pe_pcp_composite_scores":
      let compositeScoresByPcp = action.payload.data.pcp_groups;
      const pcpComposites = compositeScoresByPcp.filter((el: { composite_score: string, pcp_group: string }) => {
        if (el.composite_score == "") {
          return false;
        }
        if (action.payload.health_plan == "Prominence Health Plan") {
          return CONST.PCP_GROUPS_PHP.some((i) => i === el.pcp_group)
        } else if (action.payload.health_plan == "Wynn Resorts Health Plan"){
          return CONST.PCP_GROUPS_WYNN.some((i) => i === el.pcp_group)
        } else {
          return CONST.PCP_GROUPS.some((i) => i === el.pcp_group)
        }
      }
      );
      return {
        ...state,
        peMeasures: {
          ...state.peMeasures,
          compositeScoresByPcp: pcpComposites.map(
            (i: { composite_score: any; pcp_group: string }) => {
              return {
                composite_score: Math.round(i.composite_score * 100),
                pcp_group: i.pcp_group,
              };
            }
          ),

          composite: {
            composite_score: Math.round(action.payload.data.overall * 100),
            pcp_group: "Combined Composite Score (All PCPs)",
          },
        },
      };

    case "set_current_pe_filters":
      return {
        ...state,
        peFilters: {
          plan_sponsor:
            state.user.type === "PlanSponsor" ? state.user.id : action.payload.plan_sponsor,
          health_plan:
            state.user.type === "HealthPlan" ? state.user.id : action.payload.health_plan,
          pcp_group: state.user.type === "PcpGroup" ? state.user.id : action.payload.pcp_group,
          filter_year: action.payload.filter_year
            ? action.payload.filter_year
            : state.peFilters.filter_year,
        },
      };

    case "set_qm":
      const descriptions = action.payload.descriptions;
      let qm;
      let all_patients = [] as any;
      let with_account = [] as any;
      let without_account = [] as any;

      let resObj = {
        all_patients: [] as any,
        with_account: [] as any,
        without_account: [] as any,
      };

      action.payload.data.forEach((d) => {
        const date = { month: d.filter_month, year: d.filter_year };

        Object.entries(d.data.all_patients).forEach(([key, value]) => {
          const o = {
            name: key,
            date: date,
            data: value,
          };
          all_patients = [...all_patients, o];

          let all = Object.keys(d.data.all_patients).map((key) => {
            let match = all_patients.filter((i) => {
              if (i.name === key) {
                return i;
              }
            });
            return {
              name: key,
              monthly: match,
              description: "",
            };
          });

          resObj.all_patients = all;
        });
        Object.entries(d.data.with_account).forEach(([key, value]) => {
          const o = {
            name: key,
            date: date,
            data: value,
          };
          with_account = [...with_account, o];
          let wa = Object.keys(d.data.with_account).map((key) => {
            let match = with_account.filter((i) => {
              if (i.name === key) {
                return i;
              }
            });
            return {
              name: key,
              monthly: match,
              description: "",
            };
          });
          resObj.with_account = wa;
        });
        Object.entries(d.data.without_account).forEach(([key, value]) => {
          const o = {
            name: key,
            date: date,
            data: value,
          };
          without_account = [...without_account, o];
          let wo = Object.keys(d.data.without_account).map((key) => {
            let match = without_account.filter((i) => {
              if (i.name === key) {
                return i;
              }
            });
            return {
              name: key,
              monthly: match,
              description: "",
            };
          });
          resObj.without_account = wo;
        });

        let response = Object.keys(d.data.without_account).map((key) => {
          let descriptionMatch = descriptions.filter((i) => i.name === key);
          descriptionMatch = descriptionMatch[0];
          let all_match = resObj.all_patients.filter((a) => a.name === key);
          let with_account = resObj.with_account.filter((a) => a.name === key);

          let without_account = resObj.without_account.filter((w) => w.name === key);

          const responseObj = {
            ...descriptionMatch,
            monthly: {
              all_patients: all_match[0].monthly,
              with_account: with_account[0].monthly,
              without_account: without_account[0].monthly,
            },
            results: {
              all_patients: all_match[0].monthly[all_match[0].monthly.length - 1],
              with_account: with_account[0].monthly[with_account[0].monthly.length - 1],
              without_account: without_account[0].monthly[without_account[0].monthly.length - 1],
            },
          };

          return responseObj;
        });

        response.sort((a, b) => {
          let getIndex = (x: any) => CONST.QM_ORDER.indexOf(x.name);
          return getIndex(a) - getIndex(b) || a.name - b.name;
        });
        response[0].name = "composite";
        response[0].description = CONST.QM_COMPOSITE_COPY;

        qm = response;
        return qm;
      });

      return {
        ...state,
        qm: { composite: qm[0], dataSet: qm },
      };

    case "set_qm_pcp_composite_scores":
      const c = action.payload.data.pcp_groups.filter((el: { pcp_group: string }) =>
        action.payload.health_plan == "Prominence Health Plan"
          ? CONST.PCP_GROUPS_PHP.some((i) => i === el.pcp_group)
          : CONST.PCP_GROUPS.some((i) => i === el.pcp_group)
      );

      return {
        ...state,

        pcpComposites: c,
      };

    default:
      return state;
  }
};

export default Reducer;
