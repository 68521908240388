import React from "react";
import image from "../assets/images/bg.png";
import { Grid, Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { render } from "react-dom";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";

const useStyles = makeStyles(() => ({
  container: {
    margin: "5vh 0",
    textAlign: "center",
    width: "100%",
  },
  box: {
    position: "relative",
    boxSizing: "unset",

    backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
    backgroundAttachment: "fixed",
    marginTop: "-10px",
    paddingTop: "0px",
    minHeight: "95.5vh",
    filter: "saturate(0.75)",
  },
}));

interface LayoutProps {
  children: any;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const styles = useStyles();
  return (
    <Box className={styles.box}>
      <Grid className={styles.container} container>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Layout;
