import * as React from "react";
import { BrowserRouter, Route, Link, Switch } from "react-router-dom";
import Reducer from "../dashboard/reducer";
import QualityMeasures from "../dashboard/quality-measures";
import PatientExperience from "../dashboard/patient-experience";
import PatientEngagement from "../dashboard/patient-engagement";

import VerificationError from "../components/VerificationError";
import Header from "../components/Header";
import * as API from "../apis";
import ReactDOM from "react-dom";

import * as CONST from "../constants";
import Layout from "../components/Layout";
import { Card, Divider, Box, Button, StylesProvider } from "@material-ui/core";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import BarChartIcon from "@material-ui/icons/BarChartRounded";
import PeopleIcon from "@material-ui/icons/SupervisorAccountRounded";
import Icon from "../components/Icon";
import MoneyIcon from "@material-ui/icons/AttachMoney";
import RedeemIcon from "@material-ui/icons/Redeem";
import CostAndUtilization from "../dashboard/cost-and-utilization";
import Rewards from "../dashboard/rewards";
import { settings } from "cluster";

interface survey_item {
  statement: string;
  no: number;
  yes: number;
  questionid: number;
}

interface response_rate_item {
  messagecampaigntitle: string;
  noresponses: number;
  responses: number;
}

interface nps_item {
  count: number;
  option: string | number;
  statement: string;
}

interface comment_item {
  comment: string;
  created_at: string;
  nps_score: string;
  patient_first_name: string;
  patient_last_name: string;
  pcp_first_name: string;
  pcp_last_name: string;
  survey_questions: any;
}

interface survey_result {
  health_plan: string;
  pcp_group: string;
  report_type: string;
  survey_subtype: string;
  survey_type: string;
  token: string;
  data: survey_item[];
}

interface survey_response_rate {
  health_plan: string;
  pcp_group: string;
  report_type: string;
  survey_subtype: string;
  survey_type: string;
  token: string;
  data: response_rate_item[];
}

interface nps {
  health_plan: string;
  pcp_group: string;
  report_type: string;
  survey_subtype: string;
  survey_type: string;
  token: string;
  data: nps_item[];
}

interface comment {
  health_plan: string;
  pcp_group: string;
  report_type: string;
  survey_subtype: string;
  survey_type: string;
  data: comment_item[];
}

interface peMeasures {
  clinic: {
    survey_results: survey_result[];
    survey_response_rates: survey_response_rate[];
    nps: nps[];
    comments: comment[];
  };
  telehealth: {
    survey_results: survey_result[];
    survey_response_rates: survey_response_rate[];
    nps: nps[];
    comments: comment[];
  };
  compositeScoresByPcp: any;
  composite: any;
}

interface filters {
  health_plan: string;
  pcp_group: string;
  filter_year: null | number;
  plan_sponsor: null | string;
}

interface rewardsData {
  name: string;
  count: number;
  description: null | string;
  total_amount_rewarded: null | string;
  award_amount: number;
}
interface rewards {
  year: string;
  total_amount_rewarded: null | any;
  overall_count: null | number;
  data: rewardsData[];
}
type State = {
  isViewEstablished: boolean;
  user: { type: string; id: string; token: string };
  planSponsorHealthPlan: string;
  isFetching: boolean;
  filters: filters;
  peFilters: filters;
  pcpComposites: any;
  peMeasures: peMeasures;
  rewards: any;
  yearlyRewards: any;
  allPlanSponsors: any;
  allHealthPlans: any;
  planSponsors: any;
  qm: any;
  error: any;
};

const initialState: State = {
  isViewEstablished: false,
  user: {
    type: "",
    id: "",
    token: "",
  },
  planSponsorHealthPlan: "",
  isFetching: true,
  filters: {
    health_plan: "",
    pcp_group: "",
    plan_sponsor: "",
    filter_year: new Date().getFullYear(),
  },
  peFilters: {
    health_plan: "",
    pcp_group: "",
    plan_sponsor: "",
    filter_year: new Date().getFullYear(),
  },
  pcpComposites: [],
  qm: {},
  rewards: [],
  yearlyRewards: [],
  planSponsors: [],
  allPlanSponsors: [],
  allHealthPlans: [],
  peMeasures: {
    clinic: {
      survey_results: [],
      survey_response_rates: [],
      nps: [],
      comments: [],
    },
    telehealth: {
      survey_results: [],
      survey_response_rates: [],
      nps: [],
      comments: [],
    },
    compositeScoresByPcp: {},
    composite: {},
  },
  error: "",
};

export const Routes = () => {
  const [state, dispatch] = React.useReducer(Reducer, initialState);

  React.useEffect(() => {
    const params = window.location.search;
    const urlParams = new URLSearchParams(params);
    const token = urlParams.get("token");
    if (token) {
      API.verifyToken(token)
        .then((res) => {
          dispatch({
            type: "set_user_session",
            payload: { data: res.data, token: token },
          });

          API.fetchSettings({
            token: token,
          })
            .then((response: any) => {
              dispatch({ type: "set_all_health_plans", payload: response.data.health_plans });
              dispatch({ type: "set_all_plan_sponsors", payload: response.data.plan_sponsors });
              if (res.data.recipient_type == "PlanSponsor") {
                dispatch({
                  type: "set_plan_sponsor_health_plan",
                  payload: response.data.health_plan,
                });
              }
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => {
            dispatch({
              type: "set_error_verifying",
              payload: CONST.MSG_AUTH_ERROR_TOKEN + err.message,
            })

            window.location.href = 'https://www.yourwellportal.com/login'
          }
        );
    }
  }, []);

  const RewardsLink = () => {
    if (
      state.user.type == "Admin" ||
      state.user.id == "Prominence Health Plan" ||
      state.planSponsorHealthPlan == "Prominence Health Plan"
    ) {
      return (
        <Link
          key={"/rewards"}
          className={"route-link"}
          to={`${"/rewards"}?token=${state.user.token}`}
        >
          <RedeemIcon id="btn-icon" />
          {"Rewards"}
        </Link>
      );
    } else {
      return null;
    }
  };
  const Landing = () => (
    <Layout>
      <div id="qm-dashboard">
        <h1 id="title">
          <Icon />
          <br />
          {CONST.LANDING_WELCOME}
        </h1>
        <label>{CONST.LANDING_TITLE}</label>
        <Divider id="divider" />
        <Box>
          <p>{CONST.LANDING_ACCOUNT}</p>
          <label id="uppercase">{state.user.id && state.user.id}</label>
          <br />
          <FingerprintIcon id="person-icon" />
        </Box>
        <p>{CONST.LANDING_CALL_TO_ACTION}</p>
        <Box style={styles.box} className="button-group">
          {<RewardsLink />}

          <Link
            key={"/quality-measures"}
            className="route-link"
            to={`${"/quality-measures"}?token=${state.user.token}`}
          >
            <BarChartIcon id="btn-icon" />
            {"Quality Measures"}
          </Link>
          <Link
            key={"/patient-experience"}
            className="route-link"
            to={`${"/patient-experience"}?token=${state.user.token}`}
          >
            <PeopleIcon id="btn-icon" />
            {"Patient Experience"}
          </Link>
          {state.user.type == "Admin" && (
            <>
              <Link
                key={"/patient-engagement"}
                className="route-link"
                to={`${"/patient-engagement"}?token=${state.user.token}`}
              >
                <PeopleIcon id="btn-icon" />
                {"Patient Engagement"}
              </Link>
            </>
            )
          }
          {state.user.type == "Admin" && (
            <>
              <Link
                key={"/cost-and-utlization"}
                className="route-link "
                to={`${"/cost-and-utilization"}?token=${state.user.token}`}
              >
                <MoneyIcon id="btn-icon" />
                {"Cost & Utilization"}
              </Link>
            </>
          )}
        </Box>
      </div>
    </Layout>
  );

  return (
    <>
      <BrowserRouter>
        <Header />
        {state.user.token && (
          <Switch>
            <Route path={"/"} exact render={() => Landing()} />
            <Route
              path="/patient-experience"
              render={(props) => (
                <PatientExperience key={props.location.key} state={state} dispatch={dispatch} />
              )}
            />
            <Route
              path="/patient-engagement"
              render={(props) => (
                <PatientEngagement key={props.location.key} state={state} dispatch={dispatch} />
              )}
            />
            <Route
              path={"/quality-measures"}
              render={(props) => (
                <QualityMeasures key={props.location.key} state={state} dispatch={dispatch} />
              )}
            />

            <Route
              path={"/rewards"}
              render={(props) => (
                <Rewards key={props.location.key} state={state} dispatch={dispatch} />
              )}
            />
            <Route
              path={"/cost-and-utilization"}
              render={(props) => (
                <CostAndUtilization key={props.location.key} state={state} dispatch={dispatch} />
              )}
            />
          </Switch>
        )}{" "}
      </BrowserRouter>
      {!state.user.token && state.error && <VerificationError msg={state.error} />}
    </>
  );
};

const styles = {
  box: {
    padding: "2rem 0rem 5rem",
  },
} as const;

const rootElement = document.getElementById("root");
ReactDOM.render(<Routes />, rootElement);
