import * as React from "react";
import { Grid, Card, Link, IconButton, FormHelperText, Box } from "@material-ui/core";
import { Bar, Doughnut } from "react-chartjs-2";
import ReactToPrint from "react-to-print";
import InfoIcon from "@material-ui/icons/Info";
import PrintIcon from "@material-ui/icons/Print";

import Filters from "../../components/Filters";
import Loading from "../../components/Loading";
import SelectedFilters from "../../components/SelectedFilters";
import DashboardTitle from "../../components/DashboardTitle";
import BackButton from "../../components/BackButton";
import * as CONST from "../../constants";
import * as API from "../../apis";
import GraphWrapper from "./GraphWrapper";

interface RewardsProps {
  state: any;
  dispatch: (param: any) => void;
}

const Rewards: React.FC<RewardsProps> = React.memo(({ state, dispatch }) => {
  const [loading, setLoading] = React.useState(true);
  const reportRef = React.useRef<HTMLElement | null>(null);

  const filterChangeHandler = async (params: any) => {
    !loading && setLoading(true);
    dispatch({ type: "set_current_filters", payload: params });
    if (
      params.filter_year !== state.filters.filter_year &&
      params.health_plan == state.filters.health_plan
    ) {
      dispatch({
        type: "set_rewards_year",
        payload: params.filter_year,
      });
    } else {
      const data = await API.fetchRewards({
        token: state.user.token,
        health_plan: params.health_plan,
        pcp_group: params.pcp_group,
        plan_sponsor: params.plan_sponsor,
      });
      dispatch({
        filters: { filter_year: 'Cumulative'},
        type: "set_rewards_data",
        payload: data.rewards,
      });
    }
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  React.useEffect(() => {
    (async () => {
      const queryParams = {
        token: state.user.token,
        plan_sponsor: state.user.type == "PlanSponsor" ? state.user.id : null,
        health_plan: state.user.type == "HealthPlan" ? state.user.id : CONST.HEALTH_PLANS[0],
        pcp_group: state.user.type == "PcpGroup" ? state.user.id : CONST.PCP_GROUPS[0],
        filter_year: "Cumulative",
      };

      dispatch({ type: "set_current_filters", payload: queryParams });
      // dispatch({
      //   type: "set_plan_sponsors_options",
      //   payload: state.allPlanSponsors.filter(
      //     (i: { health_plan: any }) => i.health_plan == queryParams.health_plan
      //   ),
      // });

      const data = await API.fetchRewards(queryParams);
      if (data.message == "No report found") {
        dispatch({
          type: "set_rewards_data",
          payload: [],
        });
      }
      dispatch({
        type: "set_rewards_data",
        payload: data.rewards,
      });
      setTimeout(() => {
        setLoading(false);
      }, 900);
    })();
  }, []);
  return (
    <>
      {loading && <Loading msg={state.error ? state.error : CONST.MSG_FETCHING_DATA} />}
      {!loading && (
        <Card id="qm-dashboard" ref={reportRef}>
          <BackButton token={state.user.token} />
          <DashboardTitle title="Rewards" view="rewards" />

          <Filters
            dispatch={dispatch}
            filterClickHandler={(params) => filterChangeHandler(params)}
            state={state}
            view={"rewards"}
            message={"FILTER REWARDS BY"}
          />

          <Grid container justify="space-around" alignItems="flex-start">
            <Grid item xs={12}>
              <SelectedFilters
                view={"rewards"}
                filters={state.filters}
                isViewEstablished={state.isViewEstablished}
              />
              <ReactToPrint
                trigger={() => (
                  <IconButton id="print-btn">
                    Print Report
                    <PrintIcon id="print-icon" />
                  </IconButton>
                )}
                content={() => reportRef.current}
              />
            </Grid>
            <Grid item xs={12} md={8} className={"donut-grid"}>
              <label>{state.rewards.year} Rewards</label>
              <Box
                position="relative"
                style={styles.doughnutBox}
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  className={"total-amount"}
                  style={styles.totalAmount}
                  top={40}
                  left={125}
                  bottom={40}
                  right={125}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <div>
                    {"$" +
                      state.rewards.total_amount_rewarded
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                    <div style={styles.totalAmountCaption}>Total Rewards</div>
                  </div>
                </Box>
                <Doughnut
                  type="doughnut"
                  options={{
                    plugins: {
                      legend: {
                        position: "bottom",
                        labels: {
                          usePointStyle: true,
                          font: {
                            size: 12,
                            weight: "normal",
                          },
                        },
                      },
                    },
                  }}
                  data={{
                    labels: [...state.rewards.data.map((i) => i.name)],
                    datasets: [
                      {
                        data: [...state.rewards.data.map((i) => i.total_amount_rewarded)],
                        backgroundColor: [
                          "cornflowerblue",
                          "ghostwhite",
                          "slategrey",
                          "skyblue",
                          "#26b6fa",
                          CONST.TARGET_GREEN,
                        ],
                      },
                    ],
                  }}
                />{" "}
              </Box>
              {state.filters.filter_year == "Cumulative" ? (
                state.yearlyRewards
                  .map((i, idx) => {
                    return (
                      <div key={idx}>
                        {i.year !== "Cumulative" && i.year !== new Date().getFullYear().toString()
                          ? "+"
                          : null}
                        {i.year == new Date().getFullYear().toString() && " = "}
                        <p>
                          <b>{i.year} </b> <br /> <b>{i.overall_count} </b>{" "}
                          <b id="thin">Earned Rewards</b> <br />
                          <b>
                            {"$" +
                              i.total_amount_rewarded
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
                          </b>{" "}
                          <b id="thin">Total Rewards Given</b>
                        </p>
                      </div>
                    );
                  })
                  .reverse()
              ) : (
                <>
                  <p>
                    {" "}
                    <label>{state.rewards.year} Breakdown</label>
                  </p>
                  <p>
                    <b>{state.rewards.overall_count} </b> <b id="thin">Earned Rewards</b>{" "}
                    <br />
                    <b>
                      {"$" +
                        state.rewards.total_amount_rewarded
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
                    </b>{" "}
                    <b id="thin">Total Rewards Given</b>
                  </p>
                </>
              )}
            </Grid>

            <br />
            <Card id="card" style={styles.card}>
              {!state.rewards.data.length ? (
                <>
                  <div style={styles.div}>
                    <InfoIcon style={styles.info} />
                    <h3>Sorry, no data available for the filters you selected</h3>
                  </div>
                </>
              ) : (
                <Grid container spacing={0} justify="space-around" alignItems="flex-start">
                  {state.rewards.data.map((i, idx) => (
                    <GraphWrapper i={i} idx={idx} key={idx} filters={state.filters} />
                  ))}
                </Grid>
              )}
            </Card>
          </Grid>
        </Card>
      )}
    </>
  );
});

const styles = {
  totalAmount: {
    fontSize: "13px",
    color: " #3cd28f",
    height: "170px",
    width: "200px",
    background: "#06205a",
    fontWeight: "bold",
    borderRadius: "100%",
    letterSpacing: "0.01em",
  },
  totalAmountCaption: {
    color: "#fff",
    marginTop: "5px",
    textTransform: "uppercase",
    fontSize: "10px",
  },
  doughnutBox: {
    padding: "0px",
    width: "450px",
    margin: "auto",
  },
  p: { textAlign: "left" },
  div: {
    padding: "7rem 20rem",
    borderRadius: "10px",
    background: "#eef2f887",
  },
  info: { fontSize: "14rem", marginBottom: "1rem", color: "#abc0e187" },
  card: {
    paddingBottom: "5rem",
    textAlign: "left",
  },
  graphCard: {
    boxShadow: "none",
    margin: "auto",
    padding: "10px",
    textAlign: "left",
  },
} as const;

export default Rewards;
