import * as React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Routes } from "./routes";

ReactDOM.render(<Routes />, document.querySelector("#root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
